import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import cx from 'classnames';
import moment from 'moment';

import { useStyles } from './styles';

import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import { getReferrersReport } from '../../api/campaigns';
import { getReferrersStats } from '../../api/stats';
import { useRef } from 'react';
import { numberWithCommas, periodsForReferrersStats } from '../../utils/utils';

const options = {
  parsing: {
    xAxisKey: 'y',
  },
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          return `$${context.formattedValue}`;
        },
      },
    },
    legend: {
      display: false,
      position: 'right',
    },
    datalabels: {
      color: '#fff',
      anchor: 'end',
      align: '',
      clamp: true,
      formatter: function (value) {
        return `$${numberWithCommas(value.y)}`;
      },
    },
  },
  scales: {
    y: {
      grid: {
        borderColor: '#515151',
        tickColor: '#515151',
        color: '#515151',
      },
      ticks: {
        color: '#fff',
      },
    },
    x: {
      grid: {
        borderColor: '#515151',
        tickColor: '#515151',
        color: '#515151',
      },
      grace: '5%',
      ticks: {
        color: '#fff',
        padding: 10,
      },
      title: {
        align: 'end',
        display: true,
        color: '#fff',
      },
    },
  },
};

export default function ReferrersStats() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const initialState = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
      },
    ],
  };
  const [chartData, setChartData] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('thisMonth');
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const ref = useRef();

  const getData = () => {
    setLoading(true);
    getReferrersStats({ startDate, endDate })
      .then((result) => {
        setChartData(result);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getData();
  }, [period]);

  const setDateInterval = ({ start, end, value }) => {
    setStartDate(() => start);
    setEndDate(() => end);
    setPeriod(value);
  };

  const onClick = async (e, elements, chart) => {
    const { id: referrId } = chart.data.datasets[0].data[elements[0].index];
    const code = chart.data.labels[elements[0].index];
    const blob = await getReferrersReport({ referrId, startDate, endDate });
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${code}_${Date.now()}.csv`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <div className={`main container ${classes.container}`}>
      <Grid container item md={12} justifyContent="space-between" alignItems="center">
        <Typography className={classes.title}>Referrers</Typography>
        <Grid
          container
          style={{ marginTop: 15 }}
          item
          md={12}
          justifyContent="space-between"
          alignItems="center"
        >
          {periodsForReferrersStats.map((item, i) => {
            const active = period === item.value;
            return (
              <Grid container key={i} item md={3} justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  className={cx(classes.periodBtn, { active })}
                  type="button"
                  onClick={() => setDateInterval({ ...item.getTimeInterval(), value: item.value })}
                >
                  {item.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          container
          style={{ marginTop: 15 }}
          item
          md={12}
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Bar
              ref={ref}
              data={chartData}
              options={{ ...options, onClick }}
              plugins={[ChartDataLabels]}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
