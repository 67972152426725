import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';

const AdExample = ({ loading, campaign }) => {
  const classes = useStyles();
  const { all_native_ads } = campaign || {};
  const firstAd = all_native_ads?.[0];
  const link = firstAd?.creatives?.[0]?.url || '';
  const title = firstAd?.input_data?.heading || '';
  const subtitle = firstAd?.input_data?.tagline || '';

  const renderAd = () => (
    <Grid container direction="column" item md={12} justifyContent="center" alignItems="center">
      <img src={link} alt={title} className={classes.adExampleImage} />
      <Typography className={classes.adExampleTitle}>{title}</Typography>
      <Typography className={classes.adExampleSubtitle}>{subtitle}</Typography>
    </Grid>
  );
  return loading ? <CircularProgress /> : renderAd();
};

export default AdExample;
