import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const getRevenue = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/revenue`, { headers, params });
  return res.data;
}

export const getUsersStats = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/users`, { headers, params });
  return res.data;
}

export const getReferrersStats = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/referrers`, { headers, params });
  return res.data;
}

export const getRssStats = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/rss`, { headers, params });
  return res.data;
}

export const getMostPopularDays = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/most-popular-days`, { headers, params });
  return res.data;
}

export const getDailyRevenue = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/daily`, { headers, params });
  return res.data;
}

export const getVisitors = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/visitors`, { headers, params });
  return res.data;
} 