import React, { useEffect } from 'react';

import Visitors from './Visitors';
import ErrorsLog from './ErrorsLog';

export default function ErrorsStats() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <>
      <Visitors />
      <ErrorsLog />
    </>
  );
}