import React from 'react';
import cx from 'classnames';
import preloader from '../../assets/images/mowpod-boost-logo.png';
import { useLoading } from '../../contexts/LoadingContext';

export default function Preloader() {
  const { loading } = useLoading();
  return (
    <div className={cx("preloader", { 'show-preloader': loading })}><img src={preloader} alt="logotype" /></div>
  )
}