import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { getUser, setSession } from '../api/auth';

const cookies = new Cookies();

const CurrentUserContext = React.createContext();
export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUser()
      .then((res) => {
        setSession({ userId: res._id }).then((session) => {
          if(session.id) cookies.set('mowSessionId', session.id);
        });
        setUser(res);
        setLoading(false);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          setSession().then((session) => {
            if(session.id) cookies.set('mowSessionId', session.id);
          });
          setUser(null);
          localStorage.removeItem('auth_token');
        }
        setLoading(false);
      });
  }, []);

  return (
    <CurrentUserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {!loading && children}
    </CurrentUserContext.Provider>
  );
};
