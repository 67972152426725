/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */

import megaphoneLogo from '../assets/images/megaphone.png';
import art19Logo from '../assets/images/art19.png';
import omnyLogo from '../assets/images/omny.svg';
import podbeanLogo from '../assets/images/podbean_logo.svg';
import nextBroadcast from '../assets/images/next-broadcast.png';
import moment from 'moment';

export const getHeaders = () => {
  const authToken = `${localStorage.getItem('auth_token')}`;
  return {
    JWTAuthorization: authToken || '',
  };
};

export const compareObjects = (elemA, elemB) => JSON.stringify(elemA) === JSON.stringify(elemB);

export const cloneObject = obj => JSON.parse(JSON.stringify(obj));

export const getHost = () => `${window.location.protocol}//${window.location.hostname}`;

export const isHS = () => window.location.hostname === 'hubspot.mowpod.com';

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const validateEmail = email => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

export const findUrlInString = str => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return str.match(urlRegex) ? str.match(urlRegex)[0] : str;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const serialize = (obj, prefix) => {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? `${prefix}[${Array.isArray(obj) ? '' : p}]` : p;
      let v = obj[p];
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      );
    }
  }
  return str.join('&');
};

export const prepareStatsFilters = filters => {
  const preparedFilters = {};
  Object.keys(filters).forEach(key => {
    if (filters[key].values.length) {
      preparedFilters[key] = filters[key];
    }
  });
  return preparedFilters;
};

export const isInt = n => Number(n) === n && n % 1 === 0;

export const boostInput = (boost, price) =>
  Math.floor(boost / (price || getPartner()?.costPerDownload || 0.9));
export const resize = el => {
  el.style.height = 'inherit';
  el.style.maxHeight = '200px';
  el.style.height = `${el.scrollHeight}px`;
};

export const getLength = str => str.replace(/ /g, '').length;

export const addSpacesCard = value => {
  const cleanNum = value.replace(/[^0-9]/g, '').replace(/\W/gi, '');
  if (cleanNum.length === 15) {
    return cleanNum.replace(/(.{4})(.{6})(.{5})/g, '$1 $2 $3').trim();
  }
  return cleanNum.replace(/(.{4})/g, '$1 ').trim();
};

export const getPartner = () => {
  switch (window.location.hostname) {
    case 'megaphone.mowpod.com':
      return {
        partnerName: 'megaphone',
        logo: megaphoneLogo,
        costPerDownload: 0.8,
        url: 'https://feeds.megaphone.fm/mypodcast',
        howThisWorks: `Megaphone is working with mowPod Boost! 
          The mowPod Boost self-serve platform drives podcast listeners to any
          episode of your podcast leveraging the same mowDSP targeting and technology some
          of the largest podcasts and networks use to grow their shows. Simply add your
          RSS feed, pick an episode, enter a budget, edit the ad title and description,
          and mowPod takes care of the rest! We'll create the ad campaign and drive IAB
          2.0 compliant downloads from hundreds (or thousands) of websites to your show!
          Monitor campaign stats in near real time (2 hour delay) on your stats page or
          follow along in your podcast hosting platform.`,
      };
    case 'art19.mowpod.com':
      return {
        partnerName: 'art19',
        logo: art19Logo,
        costPerDownload: 0.9,
        howThisWorks: `The mowPod Boost self-serve platform drives podcast listeners to any
          episode of your podcast leveraging the same mowDSP targeting and technology some
          of the largest podcasts and networks use to grow their shows. Simply add your
          RSS feed, pick an episode, enter a budget, edit the ad title and description,
          and mowPod takes care of the rest! We'll create the ad campaign and drive IAB
          2.0 compliant downloads from hundreds (or thousands) of websites to your show!
          Monitor campaign stats in near real time (2 hour delay) on your stats page or
          follow along in your podcast hosting platform.`,
      };
    case 'omny.mowpod.com':
      return {
        title: 'Omny + mowPod Boost',
        logo: omnyLogo,
        partnerName: 'omny',
        howThisWorks: `Omny has partnered with mowPod to let you boost your episodes and get more Downloads to your podcast! 
          The mowPod Boost self-serve platform drives podcast listeners to any
          episode of your podcast leveraging the same mowDSP targeting and technology some
          of the largest podcasts and networks use to grow their shows. Simply add your
          RSS feed, pick an episode, enter a budget, edit the ad title and description,
          and mowPod takes care of the rest! We'll create the ad campaign and drive IAB
          2.0 compliant downloads from hundreds (or thousands) of websites to your show!
          Monitor campaign stats in near real time (2 hour delay) on your stats page or
          follow along in Omny!`,
        textBottom: 'Downloads will appear as mowpod.com in your Omny statistics',
        costPerDownload: 0.8,
      };
    case 'podbean.mowpod.com':
      return {
        partnerName: 'podbean',
        logo: podbeanLogo,
        costPerDownload: 0.72,
        howThisWorks: `Boost your podcast's reach with mowPod Boost - your self-serve platform for driving podcast listeners, 
          powered by our cutting-edge mowDSP technology. Now in collaboration with Podbean, we're on a mission to help 
          Podbean podcasters connect with a broader audience. Add your RSS feed, choose an episode, set a budget, 
          and customize your ad - we'll handle the rest, creating IAB 2.0 compliant ad campaigns to amplify your show. 
          Track your growth with near-real-time stats or through your Podbean hosting platform. Grow big with mowPod Boost!        `,
      };
    case 'nextbroadcastmedia.mowpod.com':
      return {
        partnerName: 'nextbroadcastmedia',
        logo: nextBroadcast,
        costPerDownload: 0.9,
        howThisWorks: `Next Broadcast Media has partnered with mowPod to let you boost your episodes and get more Downloads to your podcast! 
          The mowPod Boost self-serve platform drives podcast listeners to any
          episode of your podcast leveraging the same mowDSP targeting and technology some
          of the largest podcasts and networks use to grow their shows. Simply add your
          RSS feed, pick an episode, enter a budget, edit the ad title and description,
          and mowPod takes care of the rest! We'll create the ad campaign and drive IAB
          2.0 compliant downloads from hundreds (or thousands) of websites to your show!
          Monitor campaign stats in near real time (2 hour delay) on your stats page!`,
      };
    default:
      return {
        costPerDownload: 0.9,
        howThisWorks: `The mowPod Boost self-serve platform drives podcast listeners to any
          episode of your podcast leveraging the same mowDSP targeting and technology some
          of the largest podcasts and networks use to grow their shows. Simply add your
          RSS feed, pick an episode, enter a budget, edit the ad title and description,
          and mowPod takes care of the rest! We'll create the ad campaign and drive IAB
          2.0 compliant downloads from hundreds (or thousands) of websites to your show!
          Monitor campaign stats in near real time (2 hour delay) on your stats page or
          follow along in your podcast hosting platform.`,
      };
  }
};

export const changeTitle = () => {
  document.title = getPartner()?.title || document.title;
};

export const addSlashCardDate = value => {
  value = value
    .replace(/[^0-9]/g, '')
    .replace(/(.{2})/g, '$1/')
    .replace(/^\/|\/$/g, '');
  for (let i = 0; i < value.length; i++) {
    switch (i) {
      case 0:
        if (value[i] > 1) {
          value = `0${value[i]}`;
          i++;
        }
        break;
      case 1:
        value = +value[i - 1] === 1 && value[i] > 2 ? '12' : value;
        break;
      default:
        break;
    }
  }
  return value;
};

export const periodsForCampaignStats = [
  {
    label: 'Today',
    value: 'today',
    getTimeInterval: () => ({
      start: moment().startOf('day').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    getTimeInterval: () => ({
      start: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 7 days',
    value: 'week',
    getTimeInterval: () => ({
      start: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Month to date',
    value: 'currentMonth',
    getTimeInterval: () => ({
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last month',
    value: 'lastMonth',
    getTimeInterval: () => ({
      start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 12 months',
    value: 'lastTwelveMonths',
    getTimeInterval: () => ({
      start: moment().subtract(12, 'month').add(1, 'days').startOf('day').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },  
];

export const periodsForReferrersStats = [
  {
    label: 'This Month',
    value: 'thisMonth',
    getTimeInterval: () => ({
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last month',
    value: 'last12Months',
    getTimeInterval: () => ({
      start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 6 months',
    value: 'last6Months',
    getTimeInterval: () => ({
      start: moment().subtract(6, 'month').add(1, 'days').startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'All Time',
    value: 'allTime',
    getTimeInterval: () => {},
  },
];

export const periodsForDailyRevenueStats = [
  {
    label: 'This Week',
    value: 'thisWeek',
    getTimeInterval: () => ({
      start: moment().startOf('week').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'This Month',
    value: 'thisMonth',
    getTimeInterval: () => ({
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last month',
    value: 'last12Months',
    getTimeInterval: () => ({
      start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 6 months',
    value: 'last6Months',
    getTimeInterval: () => ({
      start: moment().subtract(6, 'month').add(1, 'days').startOf('day').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
];

export const periodsForUserStats = [
  {
    label: 'Last 6 months',
    dateType: 'month',
    value: 'last6Months',
    getTimeInterval: () => ({
      start: moment().subtract(6, 'month').startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 12 months',
    value: 'last12Months',
    dateType: 'month',
    getTimeInterval: () => ({
      start: moment().subtract(12, 'month').startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 26 Weeks',
    value: 'last26Weeks',
    dateType: 'week',
    getTimeInterval: () => ({
      start: moment().subtract(26, 'weeks').startOf('week').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 52 Weeks',
    value: 'last52Weeks',
    dateType: 'week',
    getTimeInterval: () => ({
      start: moment().subtract(52, 'weeks').startOf('week').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
];

export const logErrorToSessionStorage = errorMessage => {
  console.log('ERROR:', errorMessage);
  let sessionStorageErrorsLog = JSON.parse(sessionStorage.getItem('errorsLog')) || [];
  const errors = new Set(sessionStorageErrorsLog);

  errors.add(errorMessage);
  sessionStorageErrorsLog = Array.from(errors);
  sessionStorage.setItem('errorsLog', JSON.stringify(sessionStorageErrorsLog));
};

export const logUserIdAndPodcastNameToSessionStorage = ({ userId, podcastName }) => {
  sessionStorage.setItem('podcastName', podcastName);
  sessionStorage.setItem('userId', userId);
};

export const parseSessionStorage = (abandoned = false) => {
  const errorsLogString = sessionStorage.getItem('errorsLog');

  const errorsLogArray = JSON.parse(errorsLogString);

  const logs = {
    errorsLog: errorsLogArray || [],
    userId: sessionStorage.getItem('userId') || '',
    podcastName: sessionStorage.getItem('podcastName') || '',
  };
  abandoned && logs.errorsLog.push('Abandoned');

  return logs;
};

export const formatDateTime = date => {
  const formattedDateTime = moment(date).format('YYYY-MM-DD HH:mm');
  return formattedDateTime;
};

export const adjustDownloadPrice = (downloadPrice, boostPrice) => {
  const downloadPriceNumber = parseFloat(downloadPrice);
  if (boostPrice >= 1000) {
    const maxDiscount = 0.08;
    return (downloadPriceNumber - maxDiscount).toFixed(2);
  }
  if (boostPrice <= 200) {
    return downloadPriceNumber.toFixed(2);
  }

  const step = 101;
  const discount = Math.floor((boostPrice - step) / 100);
  const adjustedDownloadPrice = downloadPriceNumber - discount * 0.01;

  return adjustedDownloadPrice.toFixed(2);
};
