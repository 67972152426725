import creditCardType from 'credit-card-type';
import moment from 'moment';
import * as yup from 'yup';
import _ from 'lodash';
import { getRssHosts } from '../../api/rssHosts';

export const validationSchema = yup.object().shape({
  rss: yup
    .string()
    .required('RSS Feed Link is required')
    .url('RSS Feed Link must be valid URL')
    .test('', '', function (value) {
      return getRssHosts({ active: true })
        .then((rssHosts) => {
          if (value && rssHosts.some(({ host }) => value.includes(host))) {
            return true;
          }
          const getHostNames = rssHosts.map((item) => item.name).join(', ');
          return this.createError({
            message: `Sorry but we are not yet working with this podcast hosting company. We currently only work with ${getHostNames}`,
            path: 'rss',
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }),
  email: yup.string().when('logined', {
    is: false,
    then: yup.string().email('Value must be a valid email').required('Email is required'),
    otherwise: yup.string().email('Value must be a valid email'),
  }),
  password: yup.string().when('logined', {
    is: false,
    then: yup
      .string()
      .min(8, 'Must Contain 8 Characters')
      .max(32)
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[-@$!%*#?&])[A-Za-z\d-@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
      )
      .required('Password is required'),
    otherwise: yup.string(),
  }),
  title: yup.string().required('Title is required').max(46, 'Title must be at most 46 characters'),
  subtitle: yup
    .string()
    .min(5, 'The subtitle must contain at least 5 characters')
    .max(120, 'Subtitle must be at most 120 characters')
    .required('Subtitle is required'),
  boost: yup
    .string()
    .test('', 'Budget can be from $100 to $2500.', (val) => {
      if (val) {
        return val >= 100 && val < 2500;
      }
      return false;
    })
    .required('Boost is required'),
  reCaptcha: yup.boolean().oneOf([true], 'The reCaptcha must be accepted.'),
  agreeTerms: yup.boolean().oneOf([true], 'The terms must be accepted.'),
  cardNumber: yup.string().when('useSavedPaymentMethod', {
    is: true,
    then: yup.string(),
    otherwise: yup
      .string()
      .required('Card Number is required')
      .test('', 'The card number must contain 15(AMEX) or 16 digits', function (value) {
        if (value) {
          const cardNum = value.replace(/ /g, '');
          return cardNum.length === 16 || cardNum.length === 15;
        }
        return false;
      }),
  }),
  cardDate: yup.string().when('useSavedPaymentMethod', {
    is: true,
    then: yup.string(),
    otherwise: yup
      .string()
      .required('Expiration date is required')
      .test('testCardNumber', 'Expiration date incorrect', function (value) {
        if (value) {
          const cardDate = value.split('/');
          if (moment().diff(`20${cardDate[1]}-${cardDate[0]}`, 'month') < 0) {
            return true;
          }
          return false;
        }
        return false;
      }),
  }),
  cardCvv: yup.string().when('useSavedPaymentMethod', {
    is: true,
    then: yup
      .string()
      .required('CVV Code is required')
      .test('checkCvvType', 'CVV Code must contain 3 or 4(AMEX) digits', (value, props) => {
        const typeCard =
          creditCardType('').find(function (card) {
            return card.type === props.parent.cardTypeForSavedPayment;
          }) || {};
        return (
          typeCard.code.size === String(value).length || typeCard.code.size === String(value).length
        );
      }),
    otherwise: yup
      .string()
      .required('CVV Code is required')
      .test('len', 'CVV Code must contain 3 or 4(AMEX) digits', (val, props) => {
        if (props.parent.cardNumber) {
          const cardNum = props.parent.cardNumber.replace(/ /g, '');
          if (cardNum.length === 15) {
            return val && val.length === 4;
          }
          return val && val.length === 3;
        }
      }),
  }),
  cardName: yup.string().when('useSavedPaymentMethod', {
    is: true,
    then: yup.string(),
    otherwise: yup
      .string()
      .max(32, 'Name on Card is too long')
      .required('Name on Card is required'),
  }),
  zipCode: yup.string().when('useSavedPaymentMethod', {
    is: true,
    then: yup.string(),
    otherwise: yup.string().required('ZIP Code is required'),
  }),
});
