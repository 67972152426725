import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';

import { useStyles } from './styles';
import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import { Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import Chart from './charts/Chart';
import { getUsersStats } from '../../api/stats';
import { numberWithCommas, periodsForReferrersStats } from '../../utils/utils';
import { initialOptions } from './charts/initialData';

export default function FirstTimeCampaigns() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const [chartData, setChartData] = useState();
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('thisMonth');
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    setLoading(true);
    setChartData();
    getUsersStats({ startDate, endDate }).then((result) => {
      setChartData(result);
      setLoading(false);
    });
  }, [period]);

  const setDateInterval = ({ start, end, value }) => {
    setStartDate(() => start);
    setEndDate(() => end);
    setPeriod(() => value);
  };

  return (
    <div className={`main container ${classes.container}`}>
      <Grid
        container
        direction="row"
        item
        md={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>First time campaigns | Repeat campaigns.</Typography>
        <Grid
          container
          direction="row"
          style={{ marginTop: 15 }}
          item
          md={12}
          justifyContent="space-between"
          alignItems="center"
        >
          {periodsForReferrersStats.map((item, i) => {
            const active = period === item.value;
            return (
              <Grid
                container
                key={i}
                direction="row"
                item
                md={3}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  className={cx(classes.periodBtn, { active })}
                  type="button"
                  onClick={() => setDateInterval({ ...item.getTimeInterval(), value: item.value })}
                >
                  {item.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        {chartData && (
          <>
            <Typography
              style={{ color: chartData.datasets[0].backgroundColor[0], fontWeight: 700 }}
            >
              New campaigns: $
              {numberWithCommas(chartData.datasets[0].data.reduce((a, b) => a + b, 0))} |{' '}
              {numberWithCommas(chartData.countNew)} campaigns
            </Typography>
            <Typography
              style={{ color: chartData.datasets[1].backgroundColor[0], fontWeight: 700 }}
            >
              Repeat campaigns: $
              {numberWithCommas(chartData.datasets[1].data.reduce((a, b) => a + b, 0))} |{' '}
              {numberWithCommas(chartData.countRepeat)} campaigns
            </Typography>
          </>
        )}
        <Grid
          container
          direction="row"
          style={{ marginTop: 15 }}
          item
          md={12}
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Chart data={chartData} customOptions={{ ...initialOptions }} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
