import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const createErrorsLog = async params => {
  const headers = getHeaders();
  const res = await axios.post('/api/errors-log', params, { headers });
  return res.data;
};

export const getErrorsLog = async () => {
  const headers = getHeaders();
  const res = await axios.get('/api/errors-log', { headers });
  return res.data;
};
