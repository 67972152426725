import { faCircleCheck, faPenToSquare, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles(theme => ({
  input: {
    color: '#fff',
    '& .MuiInput-root': {
      marginTop: 25,
      [theme.breakpoints.up('md')]: {
        marginTop: 18,
      },
      '& .MuiInputBase-input': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
  },
  inputCode: {
    paddingRight: 10,
  },
  referrer: {
    paddingTop: 20,
    minHeight: 79,
  },
  activeLabel: {
    '&.MuiFormControlLabel-label': {
      fontSize: 14,
      color: '#5665d2',
    },
  },
  activeLabelDisabled: {
    '&.MuiFormControlLabel-label.Mui-disabled': {
      color: '#acacac',
    },
  },
  btnWrapper: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 5,
    },
    paddingTop: 15,
  },
  switchWrapper: {
    position: 'relative',
    top: -3,

    [theme.breakpoints.up('md')]: {
      top: -5,
    },
  },
}));

const MowSwitch = withStyles({
  switchBase: {
    color: '#bdbdbd',
    '&$checked': {
      color: orange[500],
    },
    '&$checked + $track': {
      backgroundColor: orange[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function Referrer({ referrer, index, saveReferrer, setEditeble, deleteReferrer }) {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const [price, setPrice] = useState('');
  const [active, setActive] = useState(false);
  const [open, setOpen] = useState();
  const inputRef = useRef(null);

  useEffect(() => {
    setCode(referrer.code || '');
    setPrice(referrer.price || '');
    setActive(referrer.active);
  }, [referrer]);

  const handleCode = (e) => setCode(e.target.value);
  const handlePrice = (e) => setPrice(e.target.value);

  const handleSaveReferrer = () => {
    const params = { code, price, active };
    if (!referrer.isNew) {
      params.id = referrer._id;
    }
    return saveReferrer(params);
  };

  useEffect(() => {
    if (!referrer.isEditable) return;
    inputRef.current.focus();
  }, [referrer.isEditable]);

  const handleEditeble = (value) => () => {
    if (!value) {
      setActive(referrer.active);
    }
    setEditeble(index, value);
  };

  const hendleRemove = () => {
    deleteReferrer(open);
    handleClose();
  };

  const handleActive = () => {
    setActive(!active);
  };

  const handleClose = () => setOpen(null);

  const renderReferrer = isEditable => (
    <Grid
      className={classes.referrer}
      container
      direction="row"
      item
      xs={12}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid container direction="row" item xs={3} justifyContent="flex-start" alignItems="center" className={classes.inputCode}>
        <TextField
          inputRef={inputRef}
          className={classes.input}
          onChange={handleCode}
          value={code}
          label="Code"
          disabled={!isEditable}
        />
      </Grid>
      <Grid container direction="row" item xs={3} justifyContent="flex-start" alignItems="center">
        <TextField
          label="Price per Download"
          type="number"
          onChange={handlePrice}
          value={price}
          disabled={!isEditable}
          className={classes.input}
        />
      </Grid>
      <Grid
        container
        direction="row"
        item
        xs={3}
        justifyContent="flex-start"
        alignItems="center"
        className={classes.switchWrapper}
      >
        {!referrer.isNew && (
          <FormControlLabel
            control={
              <MowSwitch checked={!!active} onChange={handleActive} disabled={!isEditable} color="primary" />
            }
            label="Active"
            classes={{
              label: classes.activeLabel,
              disabled: classes.activeLabelDisabled,
            }}
            labelPlacement="top"
          />
        )}
      </Grid>
      <Grid
        container
        direction="row"
        item
        xs={3}
        justifyContent="flex-end"
        alignItems="center"
        className={classes.btnWrapper}
      >
        <Grid container direction="row" item md={2} xs={6} justifyContent="flex-end" alignItems="center">
          <IconButton onClick={isEditable ? handleSaveReferrer : handleEditeble(true)}>
            <FontAwesomeIcon
              icon={isEditable ? faCircleCheck : faPenToSquare}
              size="xs"
              style={{ color: isEditable ? 'green' : '#ff812e' }}
            />
          </IconButton>
        </Grid>
        <Grid container direction="row" item md={2} xs={6} justifyContent="flex-end" alignItems="center">
          <IconButton onClick={isEditable ? handleEditeble(false) : () => setOpen(referrer._id)}>
            <FontAwesomeIcon
              icon={isEditable ? faXmark : faTrash}
              size="xs"
              style={{ color: isEditable ? 'red' : '#e84719' }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      {renderReferrer(referrer.isEditable || referrer.isNew)}
      <Dialog open={!!open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Typography variant="h6">Delete this referrer?</Typography>
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={hendleRemove} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
