import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import { faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { getErrorsLog } from '../../api/errorsLog';
import { useStyles } from './styles';
import { formatDateTime } from '../../utils/utils';
import { LOGS } from '../../utils/consts';
import cx from 'classnames';

const TABLE_TEXT = {
  PROJECT_NAME: 'Project Name',
  EMAIL: 'Email',
  DATE: 'Date',
  ...LOGS,
};

const ErrorsLog = () => {
  const classes = useStyles({});
  const [errorLogs, setErrorsLog] = useState([]);
  const [totalErrorsLog, setTotalErrorsLogLog] = useState({});

  useEffect(() => {
    getErrorsLog().then(result => {
      setErrorsLog(result.reverse());
    });
  }, []);

  useEffect(() => {
    if (errorLogs.length > 1) {
      const countTotalErrorsInLog = () => {
        const total = {};

        errorLogs.forEach(campaign => {
          campaign.errorsLog.forEach(error => {
            total[error] = (total[error] || 0) + 1;
          });
        });
        return total;
      };

      setTotalErrorsLogLog(countTotalErrorsInLog());
    }
  }, [errorLogs]);

  const createTotalRow = () => (
    <TableRow>
      {Object.values(TABLE_TEXT).map((value, colIndex) => {
        if (colIndex === 0) {
          return (
            <TableCell key={colIndex} className={cx(classes.totalCell, classes.tableCell)}>
              TOTAL
            </TableCell>
          );
        } else if (colIndex === 1 || colIndex === 2) {
          return <TableCell key={colIndex}></TableCell>;
        }
        return (
          <TableCell key={colIndex} className={cx(classes.totalCell, classes.tableCell)}>
            {totalErrorsLog[value] || 0}
          </TableCell>
        );
      })}
    </TableRow>
  );

  return (
    <>
      <div className={`main container ${classes.errorsTableContainer}`}>
        <Paper className={classes.errorsTableContainerContent}>
          <Table>
            <TableHead>
              <TableRow>
                {Object.keys(TABLE_TEXT).map(key => (
                  <TableCell
                    key={key}
                    className={cx(
                      classes.tableCell,
                      classes.whiteText,
                      TABLE_TEXT[key] === TABLE_TEXT.DATE && classes.tableCellDate,
                      TABLE_TEXT[key] === TABLE_TEXT.EMAIL && classes.tableCellEmail
                    )}
                  >
                    {TABLE_TEXT[key]}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {createTotalRow()}
              {errorLogs.map((row, index) => (
                <TableRow key={index} className={index % 2 === 0 ? classes.tableCellGreyBackGround : ''}>
                  <TableCell className={classes.tableCell}>{row.podcastName || 'N/A'}</TableCell>
                  <TableCell className={classes.tableCell}>{row.email || '-'}</TableCell>
                  <TableCell className={classes.tableCell}>{formatDateTime(row.createdAt)}</TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.TOO_MANY_CHARACTERS_TITLE) ? 'yes' : '-'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.TOO_MANY_CHARACTERS_SUBTITLE) ? 'yes' : '-'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.INVALID_RSS_FEED) ? 'yes' : '-'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.CREDIT_CARD_INVALID_NUMBER) ? 'yes' : '-'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.CREDIT_CARD_EXPIRED) ? 'yes' : '-'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.CREDIT_CARD_INCORRECT_CVV) ? 'yes' : '-'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.CAMPAIGN_RUN) ? (
                      <FontAwesomeIcon icon={faCircleCheck} size="xl" style={{ color: 'green' }} />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.ABANDONED) ? (
                      <FontAwesomeIcon icon={faXmark} size="xl" style={{ color: 'red' }} />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.errorsLog.includes(TABLE_TEXT.INTERNAL_SERVER_ERROR) ? 'yes' : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </>
  );
};

export default ErrorsLog;
