import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../../components/Header/Header';
import UserStats from '../UserStats';
import Referrers from './Referrers';
import RssHosts from './RssHosts';
import Users from './Users';
import ErrorsStats from '../ErrorStats';

export default function Admin() {
  return (
    <>
      <div className="background-sun" />
      <Header />
      <main className="main-list">
        <div className="main main-list container" data-aos="fade-up" data-aos-delay="400" data-aos-duration="400">
          <Switch>
            <Route path="/admin/" component={Users} exact/>
            <Route path="/admin/referrers" component={Referrers} exact/>
            <Route path="/admin/stats/revenue" component={UserStats} exact />
            <Route path="/admin/stats/errors" component={ErrorsStats} exact />
            <Route path="/admin/rss-hosts" component={RssHosts} exact />
          </Switch>
        </div>
      </main>
    </>
  )
}