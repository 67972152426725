import { useEffect, useState } from 'react';
import { logErrorToSessionStorage, logUserIdAndPodcastNameToSessionStorage } from '../utils/utils';
import { LOGS } from '../utils/consts';

export const useSessionStorageLogger = (formik, user) => {
  const [creditCardError, logCreditCardResponseError] = useState('');
  const [rssResponseError, logRssResponseError] = useState(null);
  const [campaignRunResponse, logCampaignRunResponse] = useState(null);
  const [internalServerError, logInternalServerError] = useState(null);

  useEffect(() => {
    formik.errors.rss && logErrorToSessionStorage(LOGS.INVALID_RSS_FEED);

    formik.errors.title &&
      formik.errors.title !== 'Title is required' &&
      formik.values.title &&
      logErrorToSessionStorage(LOGS.TOO_MANY_CHARACTERS_TITLE);

    formik.errors.subtitle &&
      formik.errors.subtitle !== 'Subtitle is required' &&
      formik.values.subtitle &&
      logErrorToSessionStorage(LOGS.TOO_MANY_CHARACTERS_SUBTITLE);
  }, [
    formik.errors.rss,
    formik.errors.title,
    formik.values.title,
    formik.values.subtitle,
    formik.errors.subtitle,
  ]);

  useEffect(() => {
    logUserIdAndPodcastNameToSessionStorage({
      userId: user?._id ?? '',
      podcastName: formik.values.title,
    });
  }, [formik.values.title, user]);

  useEffect(() => {
    creditCardError === 'Your card number is incorrect.' &&
      logErrorToSessionStorage(LOGS.CREDIT_CARD_INVALID_NUMBER);

    creditCardError === "Your card's security code is incorrect." &&
      logErrorToSessionStorage(LOGS.CREDIT_CARD_INCORRECT_CVV);

    creditCardError === 'Your card has expired.' && logErrorToSessionStorage(LOGS.CREDIT_CARD_EXPIRED);
  }, [creditCardError]);

  useEffect(() => {
    rssResponseError && logErrorToSessionStorage(LOGS.INVALID_RSS_FEED);
  }, [rssResponseError]);

  useEffect(() => {
    campaignRunResponse && logErrorToSessionStorage(LOGS.CAMPAIGN_RUN);
  }, [campaignRunResponse]);

  return {
    logCreditCardResponseError,
    logRssResponseError,
    logCampaignRunResponse,
    campaignRunResponse,
    internalServerError,
    logInternalServerError,
  };
};
