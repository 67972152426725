import cx from 'classnames';
import { Field, FormikProvider, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import logo from '../assets/images/mowpod-boost-logo.png';
import airpodsRight from '../assets/images/gifs/airpod-right.gif';
import airpodsLeft from '../assets/images/gifs/airpod-left.gif';
import { useCurrentUser } from '../contexts/CurrentUserContext';
import { login } from '../api/auth';
import { useEffect } from 'react';
import Rellax from 'rellax';
import Header from '../components/Header/Header';

export default function Login(params) {
  const { setUser } = useCurrentUser();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      login(values)
        .then((res) => {
          setUser(res.data.user);
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        });
    },
  });
  useEffect(() => {
    new Rellax(".rellax", {
      center: true,
    });
  }, [])
  return (
    <>
      <div className="backgrounds">
        <div className="background__rainbow">
          <div className={cx("background__rainbow--image rainbow--first login--rainbow rellax")} />
        </div>
        <div className="background-sun background-sun--login" />
      </div>
      <Header hideMenu />
      <div className="title--login title">
        <h1 className="rotate" data-aos="fade-up" data-aos-delay="500" data-aos-duration="300">Hello again!</h1>
      </div>
      <main className="main">
        <div className="main__login container" data-aos="fade-up" data-aos-delay="400" data-aos-duration="400">
          <img className="airpod-right rellax" data-rellax-speed={2} src={airpodsRight} alt="airpod-right" />
          <img className="airpod-left rellax" data-rellax-speed={2} src={airpodsLeft} alt="airpod-left" />
          <p>
            <span className="welcome">Welcome back!</span>
            <br />Let's login and get more downloads to your podcast!
          </p>
          <FormikProvider value={formik}>
            <form className="login-form" onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="main__login--login input-container">
                <div>
                  <label className="orange-text" htmlFor="login-form">Email</label>
                </div>
                <Field type="email" name="email" id="login-form" placeholder="Your email" />
              </div>
              <div className="main__login--password input-container">
                <div>
                  <label className="orange-text" htmlFor="login-form-password">Password</label>
                  <span>Forget your <Link to="/forgot-password">Password?</Link></span>
                </div>
                <Field type="password" name="password" minLength="6" placeholder="•••••••••••••••" id="login-form-password" />
              </div>
              <button className="submit-button" type="submit">Submit</button>
            </form>
          </FormikProvider>
          <div className="main__login--sign-up">
            <span>Not a user? <Link to="/">Sign Up</Link> here!</span>
          </div>
        </div>
      </main>
    </>
  )
}
