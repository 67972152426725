import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import Rellax from 'rellax';
import { validationSchema } from './validationSchema';
import logo from '../../assets/images/mowpod-boost-logo.png';

import { sendEmailForResetPassword } from '../../api/auth';
import { toast } from 'react-toastify';

export default function ForgotPasswordForm() {
  const [isSubmited, setIsSubmited] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    new Rellax(".rellax", {
      center: true,
    });
  }, [])
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      sendEmailForResetPassword(values)
        .then(() => setIsSubmited(true))
        .catch((err) => {
          if (err.response.data) {
            formik.setFieldError('email', err.response.data.message);
            toast.error(err.response.data.message);
          }
        });
    },
  });

  return (
    <>
      <div className="backgrounds">
        <div className="background__rainbow">
          <div className={cx("background__rainbow--image rainbow--first login--rainbow rellax")} />
        </div>
        <div className="background-sun background-sun--login" />
      </div>
      <header>
        <div className="login-header">
          <img className="heder--logo" src={logo} alt="mowpod boost logotype" />
        </div>
      </header>
      <main className="main">
        <div className="main__login container" data-aos="fade-up" data-aos-delay="400" data-aos-duration="400">
          <FormikProvider value={formik}>
            <form className="login-form" onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="main__login--login input-container">
                <div>
                  <label className="orange-text" htmlFor="login-form">Email</label>
                </div>
                <Field type="email" name="email" id="login-form" placeholder="Your email" />
                <ErrorMessage name="email">{msg => <div className='fieldError errorColor'>{msg}</div>}</ErrorMessage>
              </div>
              <button className="submit-button" type="submit">Submit</button>
              {isSubmited && <div>Check your Email</div>}
            </form>
          </FormikProvider>
        </div>
      </main>
    </>
  );
}
