export function suggestionsReducer(state, action) {
  switch (action.type) {
    case 'SET_TITLES':
      return {
        ...state,
        titles: action.data,
      };
    case 'SET_SUBTITLES':
      return {
        ...state,
        subtitles: action.data,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.data,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.data,
      };
    default:
      return {
        ...state,
      };
  }
}
