import { useEffect, useState } from 'react';

import { getCampaign } from '../api/campaigns';

import podMatch from '../assets/images/podmatch.png';
import podMachine from '../assets/images/podmachine.png';
import nextBroadcast from '../assets/images/next-broadcast.png';
import Rellax from 'rellax';
import Preloader from '../components/Preloader/Preloader';
import Header from '../components/Header/Header';
import { useHistory } from 'react-router-dom';
import { StackAdaptPixel } from '../utils/stackAdapt';
import earbud from '../assets/images/earbud.png';

export default function ThankYou() {
  const [campaign, setCampaign] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    const campaignId = searchParams.get('campaignId');
    if (campaignId) {
      getCampaign(campaignId)
        .then((result) => {
          setCampaign(result);
        })
        .catch((e) => {
          console.log('e :>> ', e);
        });
      StackAdaptPixel();
    }
  }, []);

  useEffect(() => {
    if (campaign) {
      const rellax = new Rellax('.rellax', {
        center: true,
      });
      return () => {
        rellax.destroy();
      };
    }
  }, [campaign]);

  return (
    <>
      <Preloader />
      {campaign && (
        <>
          <div className="backgrounds">
            <div className="background__rainbow">
              <div className="background__rainbow--image rainbow--first thank-you--rainbow rellax" />
            </div>
            <div className="background-sun"></div>
          </div>
          <Header />
          <div className="thank-you-title title">
            <h1 className="rotate" data-aos="fade-up" data-aos-delay="500" data-aos-duration="300">
              Thank you!
            </h1>
          </div>
          <main className="thnx">
            <div className="main container">
              <div
                className="thank-you section-description"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="400"
              >
                <p>
                  <span className="orange-text">Your boost campaign for </span>
                  <br />
                  {campaign.title}
                  <br />
                  <span className="orange-text">will begin shortly!</span>
                </p>
                <img
                  className="podcast-preview"
                  src={campaign.episode.itunes.image || earbud}
                  alt="podcast preview"
                />
                <span className="charge-credit">
                  The charge on your credit card will appear as mowMedia, LLC
                </span>
                <button
                  className="submit-boost--button submit-button"
                  onClick={() => history.push('/campaigns')}
                >
                  CAMPAIGN LIST
                </button>
              </div>
              <div
                className="thank-you section-description"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="400"
              >
                <p className="thank-you section-text">
                  In addition to boost, here are a few amazing services that we highly recommend to help grow
                  your podcast in various ways, getting guests, saving time and money on editing, and earning
                  new revenue!
                </p>
                <div className="thank-you section-visit-podcast">
                  <div className="thank-you podcast">
                    <img src={podMatch} alt="" />
                    <p>
                      PodMatch provides another way for you to grow your podcast. They bring you great guests
                      for your show AND help you be a guest on other podcasts. We've used this a bunch of
                      times and love it.
                    </p>
                    <a href="https://podmatch.com/" target="_blank">
                      <button className="thank-you submit-button">VISIT</button>
                    </a>
                  </div>
                  <div className="thank-you podcast">
                    <img src={podMachine} alt="" />
                    <p>
                      Podmachine is a simple and affordable podcast marketing and editing subscription
                      platform. Try it now for FREE!
                    </p>
                    <a href="https://www.podmachine.com/" target="_blank" className="podmachine">
                      <button className="thank-you submit-button">VISIT</button>
                    </a>
                  </div>
                  <div className="thank-you podcast">
                    <img src={nextBroadcast} alt="" className="next" />
                    <p>
                      Our Podcast Advertising solution allows you to earn revenue by reading targeted
                      host-read ads to your audience. These ads are carefully selected to align with your
                      brand and message.
                    </p>
                    <a href="https://nextbroadcast.media/" target="_blank">
                      <button className="thank-you submit-button">VISIT</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      )}
    </>
  );
}
