import { faCircleCheck, faPenToSquare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  withStyles,
} from '@material-ui/core';
import { orange } from '@material-ui/core/colors';
import React, { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  input: {
    paddingRight: 5,
    color: '#fff',
    '& .MuiInput-root': {
      marginTop: 25,
      [theme.breakpoints.up('md')]: {
        marginTop: 18,
      },
      '& .MuiInputBase-input': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    },
    // '& .MuiInputLabel-formControl': {
    //   [theme.breakpoints.up('md')]: {
    //     transform: 'translate(0, 24px) scale(1)',
    //   },
    //   transform: 'translate(0, 32px) scale(1)',
    // }
  },
  rssHost: {
    paddingTop: 20,
    minHeight: 79,
  },
  activeLabel: {
    '&.MuiFormControlLabel-label': {
      fontSize: 14,
      color: '#5665d2',
    },
  },
  activeLabelDisabled: {
    '&.MuiFormControlLabel-label.Mui-disabled': {
      color: '#acacac',
    },
  },
  switchWrapper: {
    position: 'relative',
    top: -3,

    [theme.breakpoints.up('md')]: {
      top: -5,
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
      marginLeft: -5,
    }
  },
  btnWrapper: {
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('md')]: {
      paddingTop: 10,
    },
    paddingTop: 15,
  },
}));

const MowSwitch = withStyles({
  switchBase: {
    color: '#bdbdbd',
    '&$checked': {
      color: orange[500],
    },
    '&$checked + $track': {
      backgroundColor: orange[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function RssHost({ rssHost, index, saveRssHost, setEditeble }) {
  const classes = useStyles();
  const [item, setItem] = useState({});
  const inputRef = useRef(null);

  useEffect(() => {
    setItem({ ...rssHost, delay: rssHost.delay || '0' });
  }, [rssHost]);

  const handleName = (e) => setItem({ ...item, name: e.target.value });
  const handleHost = (e) => setItem({ ...item, host: e.target.value });
  const handleDelay = (e) => setItem({ ...item, delay: e.target.value.replace(/[a-z]/gi, '') });
  const handleActive = () => setItem({ ...item, active: !item.active });

  const handleSaveRssHost = () => {
    const params = { ...item };
    if (!rssHost.isNew) {
      params.id = rssHost._id;
    }
    return saveRssHost(params);
  };

  useEffect(() => {
    if (!rssHost.isEditable) return;
    inputRef.current.focus();
  }, [rssHost.isEditable]);

  const handleEditeble = (value) => () => {
    if (!value) {
      setItem({ ...item, active: rssHost.active });
    }
    setEditeble(index, value);
  };

  const renderRssHost = (isEditable) => (
    <Grid
      className={classes.rssHost}
      container
      direction="row"
      item
      xs={12}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid container direction="row" item xs={3} justifyContent="flex-start" alignItems="center">
        <TextField
          className={classes.input}
          onChange={handleName}
          value={item.name || ''}
          label="Name"
          disabled={!rssHost.isNew}
        />
      </Grid>
      <Grid container direction="row" item xs={3} justifyContent="flex-start" alignItems="center">
        <TextField
          className={classes.input}
          onChange={handleHost}
          value={item.host || ''}
          label="Host"
          disabled={!rssHost.isNew}
        />
      </Grid>
      <Grid container direction="row" item xs={2} justifyContent="flex-start" alignItems="center">
        <TextField
          className={classes.input}
          inputRef={inputRef}
          label="Delay (sec)"
          onChange={handleDelay}
          value={item.delay || ''}
          disabled={!isEditable}
        />
      </Grid>
      <Grid container direction="row" item xs={2} justifyContent="flex-start" alignItems="center" className={classes.switchWrapper}>
        {!rssHost.isNew && (
          <FormControlLabel
            control={
              <MowSwitch
                checked={!!item.active}
                onChange={handleActive}
                disabled={!isEditable}
                color="primary"
              />
            }
            label="Active"
            classes={{
              label: classes.activeLabel,
              disabled: classes.activeLabelDisabled,
            }}
            labelPlacement="top"
          />
        )}
      </Grid>
      <Grid container direction="row" item xs={2}  alignItems="center" className={classes.btnWrapper}>
        <Grid
          container
          direction="row"
          item
          md={2}
          xs={6}
          alignItems="center"
        >
          <IconButton onClick={isEditable ? handleSaveRssHost : handleEditeble(true)}>
            <FontAwesomeIcon
              icon={isEditable ? faCircleCheck : faPenToSquare}
              size="xs"
              style={{ color: isEditable ? 'green' : '#ff812e' }}
            />
          </IconButton>
        </Grid>
        <Grid
          container
          direction="row"
          item
          md={2}
          xs={6}
          
          alignItems="center"
        >
          {isEditable && (
            <IconButton onClick={handleEditeble(false)}>
              <FontAwesomeIcon icon={faXmark} size="xs" style={{ color: 'red' }} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  return <>{renderRssHost(rssHost.isEditable || rssHost.isNew)}</>;
}
