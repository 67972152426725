import axios from "axios";

const cancelTokenSource = {};

export const getRssFeed = async (rss) => {
  if (cancelTokenSource.getRssFeed) {
    cancelTokenSource.getRssFeed.cancel('Operation canceled due to new request.');
  }
  cancelTokenSource.getRssFeed = axios.CancelToken.source();
  const res = await axios.get('/api/rss', { params: { rss }, cancelToken: cancelTokenSource.getRssFeed.token });
  return res.data;
};