import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  inputSection: {
    position: 'relative',
    paddingRight: 20,
    paddingBottom: 20,
  },
  checkBoxLabel: {
    color: '#fff',
  },
  accordionBG: {
    backgroundColor: '#0000',
  },
  accordionSummaryRoot: {
    display: 'inline-flex',
  }
}));
