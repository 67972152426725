import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import LooksOneIcon from '@material-ui/icons/LooksOne';
import LooksTwoIcon from '@material-ui/icons/LooksTwo';
import Looks3Icon from '@material-ui/icons/Looks3';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { useSuggestion } from '../../contexts/SuggestionContext';

const useStyles = makeStyles(() => ({
  origBtn: {
    fontSize: 12,
    padding: '0 10px',
    marginBottom: 2,
    '&:hover': {
      background: '#f56302',
    },
  },
  origBtnLabel: {
    paddingTop: '0 !important',
    textAlign: 'center !important',
    minWidth: '0 !important',
    color: '#000',
  },
  btn: {
    backgroundColor: '#f8a11a',
    padding: 8,
    marginTop: 10,
  },
  active: {
    backgroundColor: '#f56302',
  },
  iconColor: {
    color: '#f8a11a',
  },
  disabledIconColor: {
    color: '#c6c6c67d',
  },
  activeIcon: {
    color: '#f56302',
  },
  marginBottom: {
    marginBottom: '0 !important',
  },
  overwrite: {
    paddingTop: '0 !important',
    textAlign: 'center !important',
    fontSize: '10px !important',
    fontWeight: 700,
    paddingLeft: 6,
    alignSelf: 'flex-start',
  },
  question: {
    fontSize: 14,
  },
  disabled: {
    backgroundColor: '#c6c6c67d !important',
  },
}));

export default function CompressText({ text, handleOption, originalText, type }) {
  const classes = useStyles();
  const [activeButton, setActiveButton] = useState();
  const [options, setOptions] = useState([]);
  const { titles, subtitles, loading, error, getSuggestions } = useSuggestion();

  useEffect(() => {
    if (type === 'title' && titles) {
      setOptions(() => [...titles]);
    }
    if (type === 'subtitle' && subtitles) {
      setOptions(() => [...subtitles]);
    }
    setActiveButton(0);
  }, [titles, subtitles]);

  useEffect(() => {
    if (error) {
      setOptions([]);
    }
  }, [error]);

  const setOption = i => {
    handleOption(options[i]);
    setActiveButton(i + 1);
  };

  const setOriginalText = () => {
    handleOption(originalText || text || '');
    setActiveButton(0);
  };

  const handleReload = () => {
    if (!text) return;
    getSuggestions(text);
  };

  return (
    <>
      {(error || loading) && (
        <IconButton onClick={handleReload} disabled={loading} classes={{ label: classes.origBtnLabel }}>
          <FontAwesomeIcon icon={faRotate} spin={loading} size="lg" style={{ color: '#fff' }} />
        </IconButton>
      )}
      <Button
        variant="contained"
        className={cx(classes.origBtn, classes.btn, { [classes.active]: activeButton === 0 })}
        disabled={loading}
        classes={{
          label: classes.origBtnLabel,
          contained: cx(classes.btn),
          disabled: classes.disabled,
        }}
        onClick={setOriginalText}
      >
        Original
      </Button>
      <Grid style={{ flexDirection: 'column' }}>
        <Box component="span">
          <IconButton
            size="small"
            disabled={loading || !text || !options[0]}
            onClick={() => setOption(0)}
            classes={{
              label: classes.origBtnLabel,
            }}
          >
            <LooksOneIcon
              className={cx(classes.iconColor, {
                [classes.activeIcon]: activeButton === 1,
                [classes.disabledIconColor]: loading || !text || !options[0],
              })}
            />
          </IconButton>
          <IconButton
            size="small"
            disabled={loading || !text || !options[1]}
            onClick={() => setOption(1)}
            classes={{
              label: classes.origBtnLabel,
            }}
          >
            <LooksTwoIcon
              className={cx(classes.iconColor, {
                [classes.activeIcon]: activeButton === 2,
                [classes.disabledIconColor]: loading || !text || !options[1],
              })}
            />
          </IconButton>
          <IconButton
            size="small"
            disabled={loading || !text || !options[2]}
            onClick={() => setOption(2)}
            classes={{
              label: classes.origBtnLabel,
            }}
          >
            <Looks3Icon
              className={cx(classes.iconColor, {
                [classes.activeIcon]: activeButton === 3,
                [classes.disabledIconColor]: loading || !text || !options[2],
              })}
            />
          </IconButton>
        </Box>
        <Typography variant="caption" className={classes.overwrite}>
          SUGGESTIONS{' '}
          <Tooltip
            title="Feel free to use your original description OR choose one of the 3 A.I. suggestions. You may use these as a starting point and edit any of the text!"
            placement="bottom"
          >
            <HelpOutlineIcon className={classes.question} />
          </Tooltip>
        </Typography>
      </Grid>
    </>
  );
}
