import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { createStackAdaptCampaign } from '../../api/stackadapt';

export const RestoreButton = ({ getCampaigns, campaign }) => {
  const [loading, setLoading] = useState(false);
  const restoreCampaign = async ({ _id }) => {
    setLoading(true);
    await createStackAdaptCampaign({ campaignId: _id });
    setLoading(false);
    getCampaigns();
  };
  return (
    <Button variant="outlined" color="primary" onClick={() => restoreCampaign(campaign)}>
      {!loading ? 'Try to restore' : (<FontAwesomeIcon icon={faSpinner} size="lg" spin />)}
    </Button>
  );
};