import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { ErrorMessage, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCircleCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Grid, Typography } from '@material-ui/core';
import creditCardType, { types as CardType } from 'credit-card-type';
import masterCardLogo from '../../assets/images/icons/mastercard.svg';
import visaLogo from '../../assets/images/icons/visa.svg';
import amexLogo from '../../assets/images/icons/amex.svg';
import cardLogo from '../../assets/images/icons/credit-card.svg';
import { addSpacesCard, addSlashCardDate, getLength } from '../../utils/utils';

const CreditCard = ({ savedPaymentMethod, formik, loading }) => {
  const [CCLogo, setCCLogo] = useState(cardLogo);

  const toggleUseSavedPaymentMethod = value => {
    formik.setFieldValue('useSavedPaymentMethod', value);
    formik.setFieldValue('cardCvv', '');
    formik.setFieldError('cardCvv', '');
  };

  
  const getCardLogoByType = CCType => {
    switch (CCType) {
      case CardType.MASTERCARD:
        return masterCardLogo;
      case CardType.VISA:
        return visaLogo;
      case CardType.AMERICAN_EXPRESS:
        return amexLogo;
      default:
        return cardLogo;
    }
  };

  const getCardLogoByNum = CCNum => {
    const cardNum = CCNum.replace(/ /g, '');
    if (!cardNum) {
      return setCCLogo(cardLogo);
    }
    const card =
      creditCardType(cardNum).find(function (card) {
        return (
          card.type === CardType.MASTERCARD ||
          card.type === CardType.VISA ||
          card.type === CardType.AMERICAN_EXPRESS
        );
      }) || {};
    switch (card.type) {
      case CardType.MASTERCARD:
        setCCLogo(masterCardLogo);
        break;
      case CardType.VISA:
        setCCLogo(visaLogo);
        break;
      case CardType.AMERICAN_EXPRESS:
        setCCLogo(amexLogo);
        break;
      default:
        setCCLogo(cardLogo);
        break;
    }
  };

  const getHiddenNum = () => {
    const typeCard = creditCardType('').find(function (card) {
      return card.type === formik.values.cardTypeForSavedPayment;
    });
    return typeCard?.lengths[0] === 15 ? '**** ****** *' : '**** **** **** ';
  };

  const getLengthCvvByType = type => {
    const typeCard = creditCardType('').find(function (card) {
      return card.type === type;
    });
    return typeCard?.code.size || 3;
  };

  const getLengthCvv = () => (getLength(formik.values.cardNumber) === 15 ? '4' : '3');

  const handleCardCvv = e => {
    const { value } = e.target;
    formik.setFieldValue('cardCvv', value.replace(/[^0-9]/g, ''));
  };

  const handleCardNumber = e => {
    const { value } = e.target;
    formik.setFieldValue('cardNumber', addSpacesCard(value));
  };

  const handleCardDate = e => {
    const { value } = e.target;
    formik.setFieldValue('cardDate', addSlashCardDate(value));
  };

  useEffect(() => {
    getCardLogoByNum(formik.values.cardNumber);
  }, [formik.values.cardNumber]);

  return (
    <div className="card">
      <h3>Payment information</h3>
      {savedPaymentMethod && (
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          style={{ paddingBottom: 30 }}
        >
          <Grid item xs={2} container justifyContent="center" alignItems="center">
            <FontAwesomeIcon
              icon={formik.values.useSavedPaymentMethod ? faCircleCheck : faCircle}
              size="lg"
              style={{
                color: formik.values.useSavedPaymentMethod ? '#ff812e' : '#2a2a2a',
                cursor: !formik.values.useSavedPaymentMethod ? 'auto' : 'pointer',
              }}
              onClick={() => toggleUseSavedPaymentMethod(true)}
            />
          </Grid>
          <Grid
            className={cx('lastCard__wrapper', {
              active: formik.values.useSavedPaymentMethod,
            })}
            onClick={() => !formik.values.useSavedPaymentMethod && toggleUseSavedPaymentMethod(true)}
            item
            xs={10}
            md={6}
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              WebkitFilter: !formik.values.useSavedPaymentMethod && 'grayscale(1)',
              cursor: formik.values.useSavedPaymentMethod ? 'auto' : 'pointer',
            }}
          >
            <Grid item md={3} xs={3} container justifyContent="flex-start" alignItems="center">
              <img
                src={getCardLogoByType(savedPaymentMethod.brand)}
                width="100px"
                alt={savedPaymentMethod.brand}
              />
            </Grid>
            <Grid
              item
              md={8}
              xs={9}
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="center"
              style={{
                color: !formik.values.useSavedPaymentMethod && '#949494',
              }}
            >
              <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                {`${getHiddenNum()}${savedPaymentMethod.last4}`}
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                {savedPaymentMethod.name}
              </Grid>
              <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                <Collapse in={formik.values.useSavedPaymentMethod}>
                  <div className="lastCard__wrapper--cvv">
                    <label htmlFor="card_cvv" className="label_card_cvv">
                      CVV
                    </label>
                    <Field
                      type="text"
                      className="card_cvv"
                      name="cardCvv"
                      onChange={handleCardCvv}
                      inputMode="numeric"
                      placeholder={getLengthCvvByType(savedPaymentMethod.brand) === 4 ? '0000' : '000'}
                      maxLength={getLengthCvvByType(savedPaymentMethod.brand)}
                      size={getLengthCvvByType(savedPaymentMethod.brand)}
                    />
                  </div>
                </Collapse>
              </Grid>
            </Grid>
            {formik.values.useSavedPaymentMethod && (
              <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                {formik.errors.cardCvv && (
                  <div className="fieldError errorColor">{formik.errors.cardCvv}</div>
                )}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            style={{ padding: '30px 0 0' }}
          >
            <Typography variant="h6">OR</Typography>
          </Grid>
          <Grid item xs={2} container justifyContent="center" alignItems="center">
            <FontAwesomeIcon
              icon={!formik.values.useSavedPaymentMethod ? faCircleCheck : faCircle}
              size="lg"
              style={{
                color: !formik.values.useSavedPaymentMethod ? '#ff812e' : '#2a2a2a',
                cursor: formik.values.useSavedPaymentMethod ? 'auto' : 'pointer',
              }}
              onClick={() => toggleUseSavedPaymentMethod(true)}
            />
          </Grid>
          <Grid item xs={10} md={6} container justifyContent="center" alignItems="center">
            <Collapse in={formik.values.useSavedPaymentMethod}>
              <button
                type="button"
                onClick={() => toggleUseSavedPaymentMethod(false)}
                className="submit-boost--button submit-button"
                disabled={loading}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} size="2x" spin /> : 'Add New Card'}
              </button>
            </Collapse>
          </Grid>
        </Grid>
      )}
      <Collapse in={!formik.values.useSavedPaymentMethod}>
        <div className="card__form">
          <div className="card__form--container">
            <div className="card__form--figure">
              <div className="card-view">
                <img src={CCLogo} alt="card logo" />
                <div className="card_number">
                  <span className="set_card_number">{formik.values.cardNumber || '0000 0000 0000 0000'}</span>
                </div>
                <div className="card_name">
                  <span className="user_name">{formik.values.cardName || 'John Doe'}</span>
                </div>
                <div className="card_expire">
                  <span className="user_card_cvv">{formik.values.cardDate || 'MM/YY'}</span>
                </div>
              </div>
              <div className="card-backward">
                <div className="magn"></div>
                <div className="cvv">{formik.values.cardCvv || ''}</div>
              </div>
            </div>
            <div className="card-input-number--container">
              <div className="card-input--number">
                <label htmlFor="user_card_number_input">Card Number</label>
                <Field
                  type="text"
                  name="cardNumber"
                  inputMode="numeric"
                  id="user_card_number_input"
                  onChange={handleCardNumber}
                  placeholder="0000 0000 0000 0000"
                  maxLength="19"
                />
                <ErrorMessage name="cardNumber">
                  {msg => <div className="fieldError errorColor">{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="exp-cvv--container">
                <div className="card-input--exp">
                  <label htmlFor="user_card_exp-date">Expiration date</label>
                  <Field
                    type="text"
                    name="cardDate"
                    inputMode="numeric"
                    id="user_card_exp-date"
                    onChange={handleCardDate}
                    placeholder="MM/YY"
                    maxLength="5"
                  />
                  <ErrorMessage name="cardDate">
                    {msg => <div className="fieldError errorColor">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="card-input--cvv">
                  <label htmlFor="card_cvv">CVV Code</label>
                  <Field
                    type="text"
                    name="cardCvv"
                    id="card_cvv"
                    onChange={handleCardCvv}
                    inputMode="numeric"
                    placeholder={+getLengthCvv() === 4 ? '0000' : '000'}
                    maxLength={getLengthCvv()}
                    size={getLengthCvv()}
                  />
                  <ErrorMessage name="cardCvv">
                    {msg => <div className="fieldError errorColor">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
            </div>
          </div>
          <div className="card-input-name--container">
            <div className="card-input--name">
              <label htmlFor="user_name_input">Name on Card</label>
              <Field type="text" name="cardName" id="user_name_input" placeholder="John Doe" maxLength="25" />
              <ErrorMessage name="cardName">
                {msg => <div className="fieldError errorColor">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="card-input--zip">
              <label htmlFor="user_zip-code">Billing ZIP Code</label>
              <Field
                type="text"
                name="zipCode"
                id="user_zip-code"
                placeholder="00000"
                minLength="5"
                maxLength="6"
              />
              <ErrorMessage name="zipCode">
                {msg => <div className="fieldError errorColor">{msg}</div>}
              </ErrorMessage>
            </div>
          </div>
          <div className="card-input--login">
            Mowpod uses stripe to process all credit card transactions through a secure server with SSL.
            Mowpod does not store any credit card information.
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CreditCard;
