import React, { useState } from 'react';
import earbud from '../../assets/images/earbud.png';
import { Collapse } from '@material-ui/core';

const EpisodeDescription = ({ formik, feed }) => {
  const [showDescription, setShowDescription] = useState(false);
  const { episode } = formik.values;

  return (
    <div className="episode-description">
      {episode.itunes && (
        <img
          className="episode-description--img"
          src={episode.itunes.image || feed.itunes.image || earbud}
          alt="podcast preview"
        />
      )}
      <div className="episode-description__text">
        <div className="orange-text">
          <span>{formik.values.episode.title || ''}</span>
        </div>
        <div className="choose-episode">
          <span>{episode.itunes ? episode.itunes.author : ''}</span>
        </div>
        <Collapse in={showDescription} collapsedSize={220}>
          <div className="episode-description__text--subtitle">
            <div
              dangerouslySetInnerHTML={{
                __html: episode.content || '',
              }}
            />
          </div>
        </Collapse>
        <span
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            fontSize: 20,
            color: '#f59a00',
          }}
          onClick={() => setShowDescription(!showDescription)}
        >
          More…
        </span>
      </div>
    </div>
  );
};

export default EpisodeDescription;
