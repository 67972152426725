export const initialValues = {
  rss: '',
  email: '',
  password: '',
  episode: {},
  title: '',
  subtitle: '',
  boost: 100,
  reCaptcha: false,
  agreeTerms: false,
  cardNumber: '',
  cardDate: '',
  cardCvv: '',
  cardName: '',
  zipCode: '',
  useSavedPaymentMethod: false,
  geos: ['1242813'],
  demos: [
    '11398', '11399', '11400', '11401', '11402', '11403', '11421', '11422', '11423', '11424', '11425', '11426', '11427', '11395', '11396',
  ],
};