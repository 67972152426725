import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';

import { getCampaigns } from '../api/campaigns';

import airpodsRight from '../assets/images/gifs/airpod-right.gif';
import airpodsLeft from '../assets/images/gifs/airpod-left.gif';
import earbud from '../assets/images/earbud.png';
import Rellax from 'rellax';
import Header from '../components/Header/Header';
import { boostInput } from '../utils/utils';

const statuses = {
  in_progress: 'In Progress..',
  ended: 'Ended',
  pending: 'In Progress..',
  refunded: 'Refunded',
};

export default function CampaignList() {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const rellax = new Rellax('.rellax', {
      center: true,
    });
    getCampaigns()
      .then((result) => {
        setCampaigns(result);
      })
      .catch((e) => {
        console.log('e :>> ', e);
      });
    return () => {
      rellax.destroy();
    };
  }, []);
  return (
    <>
      <div className="backgrounds backgrounds--filter">
        <div className="background__rainbow">
          <div className="background__rainbow--image rainbow-list rainbow--first rellax" />
          <div className="background__rainbow--image rainbow-list rainbow--second rellax" />
          <div className="background__rainbow--image rainbow-list rainbow--third rellax" />
        </div>
        <div className="background-sun" />
        <Header />
        <div className="list-page title">
          <button className="submit-boost--button submit-button" onClick={() => history.push('/')}>
            + Add new campaign
          </button>
        </div>
        <main className="main-list">
          <img
            className="airpod-right rellax"
            data-rellax-speed="2"
            src={airpodsRight}
            alt="airpod-right"
          />
          <img
            className="airpod-left rellax"
            data-rellax-speed="2"
            src={airpodsLeft}
            alt="airpod-left"
          />
          <div
            className="main main-list container"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-duration="400"
          >
            <div className="campaigns">
              <span className="campaigns--span orange-text">Your Campaigns:</span>
              <div className="campaigns__list">
                {campaigns.map((item, i) => (
                  <div key={i} className="campaigns__list__podcast">
                    <Link to={`/stats/${item._id}`}>
                      <img src={item.episode.itunes.image || earbud} alt="podcast preview" />
                    </Link>
                    <div className="campaigns__list__podcast--description">
                      <h5>{item.title}</h5>
                      <span className="author">{item.episode.itunes.author}</span>
                      <span className="downloads">
                        ${item.boost} for {boostInput(item.boost, item?.price)} downloads
                      </span>
                    </div>
                    <div
                      className={cx('campaigns__list__podcast--prog', {
                        ended: item.status === 'ended',
                        refunded: item.status === 'refunded',
                      })}
                    >
                      <Link to={`/stats/${item._id}`}>{statuses[item.status]}</Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
