import axios from 'axios';
import { getHeaders } from '../utils/utils';

const cancelTokenSource = {};

export const createCampaign = async (params) => {
  const headers = getHeaders();
  const res = await axios.post('/api/campaigns', params, { headers });
  return res.data;
};

export const updateCampaign = async (params) => {
  const headers = getHeaders();
  const res = await axios.put('/api/campaigns', params, { headers });
  return res.data;
};

export const getCampaign = async (campaignId) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/campaigns/${campaignId}`, { headers });
  return res.data;
};

export const getAllCampaigns = async (params) => {
  if (cancelTokenSource.getAllCampaigns) {
    cancelTokenSource.getAllCampaigns.cancel('Operation canceled due to new request.');
  }
  cancelTokenSource.getAllCampaigns = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axios.get(`/api/campaigns/all`, { headers, params, cancelToken: cancelTokenSource.getAllCampaigns.token });
  return res.data;
}

export const getCampaigns = async () => {
  const headers = getHeaders();
  const res = await axios.get(`/api/campaigns`, { headers });
  return res.data;
}

export const getReferrersReport = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/campaigns/report`, { headers, params, responseType: 'blob' });
  return res.data;
};

export const getSourceReport = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/campaigns/source`, { headers, params, responseType: 'blob' });
  return res.data;
};
