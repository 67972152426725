import axios from 'axios';
import { getHeaders } from '../utils/utils';

const cancelTokenSource = {};

export const getCompressText = async (params) => {
  if (cancelTokenSource.getCompressText) {
    cancelTokenSource.getCompressText.cancel('Operation canceled due to new request.');
  }
  cancelTokenSource.getCompressText = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axios.get('/api/openai/compress', {
    headers,
    params,
    cancelToken: cancelTokenSource.getCompressText.token,
  });
  return res.data;
};
