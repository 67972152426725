import React, { useEffect, useRef, useState } from 'react';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { initialState } from './initialData';

const plugin = {
  id: 'increase-legend-spacing',
  beforeInit(chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 20;
    };
  },
};

export default function Chart({ data, dateType, customOptions }) {
  const [chartData, setChartData] = useState({ ...initialState });
  const [options, setOptions] = useState();
  const chartRef = useRef();

  useEffect(() => {
    if (dateType) {
      customOptions.scales.x.title.text = `year-${dateType}`;
    }
    setOptions(customOptions);
  }, [customOptions])
  

  useEffect(() => {
    if (data) {
      setChartData(data);
    }
  }, [data, dateType]);  

  return <Bar ref={chartRef} data={chartData} options={options} plugins={[ChartDataLabels, plugin]} />;
}
