import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { adminLogin } from '../../api/auth';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { boostInput } from '../../utils/utils';
import { RestoreButton } from '../RestoreButton';

import { useStyles, stylesForTableCell, stylesForTableRow } from '../../pages/Admin/Users/styles';

const StyledTableCell = stylesForTableCell(TableCell);

const StyledTableRow = stylesForTableRow(TableRow);

export default function Row({ row, getCampaigns }) {
  const { setUser } = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const classes = useStyles();

  const loginUser = ({ _id: userId, source }) => {
    adminLogin(userId)
      .then((response) => {
        setUser(response.data.user);
        window.open(`/campaigns`, '_blank').focus();
      })
      .catch((e) => {
        toast.error(e.mesage);
      });
  };

  useEffect(() => {
    if (row.campaigns.some(({ status }) => status === 'error')) {
      setHasError(true);
    }
  }, []);

  const getPrice = (campaign) => (campaign.price || 0.9) < (row.referrer?.price || 0.9)
    ? campaign.price || 0.9
    : row?.referrer?.price || 0.9;
  return (
    <>
      <StyledTableRow>
        <StyledTableCell align="left" width="200" className={classes.tBodyItem}>
          <Grid item container alignItems="center">
            <div
              className={cx(classes.marcking, { [classes.redDot]: row.hasActiveCampaigns })}
            ></div>
            <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer', paddingLeft: 20 }}>
              Campaign List
            </div>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.tBodyItem}>
          {row.email}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.tBodyItem}>
          ${row.amount}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.tBodyItem}>
          {row.referrer?.code || 'N/A'}
        </StyledTableCell>
        <StyledTableCell align="left" className={classes.tBodyItem}>
          {row.source || 'boost'}
        </StyledTableCell>
        <StyledTableCell align="right" className={cx(classes.tBodyItem, classes.nowrap)}>
          <a
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => loginUser(row)}
          >
            login
          </a>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          className={classes.tBodyItem}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <TableContainer className={classes.tTableContainer} component={Paper}>
                <Table className={classes.tTable} colSpan={7}>
                  <TableHead className={classes.tHead}>
                    <TableRow style={{ border: 'none' }}>
                      <TableCell className={classes.tHeader}>Date</TableCell>
                      <TableCell className={classes.tHeader} align="right">
                        Episode
                      </TableCell>
                      <TableCell className={classes.tHeader} align="right">
                        Status
                      </TableCell>
                      <TableCell className={classes.tHeader} align="right">
                        Downloads Purchased
                      </TableCell>
                      <TableCell className={classes.tHeader} align="right" style={{width: '80px'}}>
                        $ Paid
                      </TableCell>
                      <TableCell className={classes.tHeader} align="right">
                        Downloads Delivered
                      </TableCell>
                      {hasError && (
                        <TableCell className={classes.tHeader} align="right">
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.campaigns.map((campaign) => (
                      <StyledTableRow key={campaign._id}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          className={cx(classes.tFirstItem, classes.nowrap)}
                        >
                          {moment(campaign.createdAt).format('MM/DD/YY')}
                        </StyledTableCell>
                        <StyledTableCell align="right" className={classes.tBodyItem}>
                          {`${campaign.episode.title} (ID: ${campaign.campaignGroupsId})`}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={cx(classes.tBodyItem, classes.nowrap)}
                        >
                          {campaign.status}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={cx(classes.tBodyItem, classes.nowrap)}
                        >
                          {boostInput(campaign.boost, getPrice(campaign))}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={cx(classes.tBodyItem, classes.nowrap)}
                        >
                          ${campaign.boost}
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={cx(classes.tBodyItem, classes.nowrap)}
                        >
                          {campaign.downloadsDelivered || 'n/a'}
                        </StyledTableCell>
                        {hasError && (
                          <StyledTableCell
                            align="right"
                            className={cx(classes.tBodyItem, classes.nowrap)}
                          >
                            {campaign.status === 'error' ? (
                              <RestoreButton getCampaigns={getCampaigns} campaign={campaign} />
                            ) : (
                              <></>
                            )}
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
