import { Button, Grid, Typography, InputBase } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { createRssHost, getRssHosts, updateRssHost } from '../../api/rssHosts';
import RssHost from '../../components/RssHost';
import { toast } from 'react-toastify';
import { useSearch } from '../../hooks/useSearch';
import { useStyles } from './styles';

export default function RssHosts() {
  const classes = useStyles();
  const [rssHosts, setRssHosts] = useState([]);
  const { data, searchValue, setSearchValue } = useSearch(rssHosts, 'name');

  const loadData = () =>
    getRssHosts().then((response) => {
      setRssHosts(response);
    });

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
  }, []);

  const saveRssHost = async ({ id, ...rest }) => {
    try {
      if (id) {
        await updateRssHost(id, rest);
        return loadData();
      }
      await createRssHost(rest);
      return loadData();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const addNew = () => {
    if (!rssHosts.find((item) => item.isNew)) {
      setRssHosts([{ isNew: true }, ...rssHosts]);
    }
  };

  const setEditeble = (i, value) => {
    const copyRssHosts = [...rssHosts];
    copyRssHosts[i].isEditable = value;
    if (copyRssHosts[i].isNew && !value) {
      copyRssHosts.splice(i, 1);
    }
    setRssHosts(copyRssHosts);
  };

  const handleEscKey = e => {
    if (e.keyCode === 27) {
      setSearchValue('');
    }
  };

  const handleSearch = e => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <Grid
        className={classes.wrapper}
        container
        direction="row"
        item
        md={12}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid container direction="row" item md={12} justifyContent="flex-start" alignItems="flex-start">
          <Grid className={classes.title} container direction="row" item md={7}>
            <Typography variant="h4">RSS Hosts</Typography>
          </Grid>
          <Grid container direction="row" item md={3} justifyContent="flex-end">
            <InputBase
              className={classes.input}
              placeholder="Search"
              value={searchValue}
              onKeyDown={handleEscKey}
              onChange={handleSearch}
              inputProps={{ 'aria-label': 'search rss hosts' }}
            />
          </Grid>
          <Grid className={classes.btnWrapper} container direction="row" item md={2} justifyContent="flex-end">
            <Button variant="outlined" color="primary" className={classes.btn} onClick={addNew}>
              Add New
            </Button>
          </Grid>
        </Grid>
        <Grid
          className={classes.list}
          container
          direction="row"
          item
          md={12}
          justifyContent="flex-start"
          alignItems="center"
        >
          {data.map((rssHost, i) => (
            <RssHost
              key={i}
              rssHost={rssHost}
              index={i}
              saveRssHost={saveRssHost}
              setEditeble={setEditeble}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
}
