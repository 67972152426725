import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../contexts/CurrentUserContext';

export const PrivateRoute = ({ component: Component, onlyAdmin, ...rest }) => {
  const { user } = useCurrentUser();
  const isLoggedIn = !!user;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />);
        }
        if (user.admin && !rest.path.includes('/admin')) {
          return (<Redirect to={{ pathname: '/admin', state: { from: props.location } }} />);
        }
        if (!user.admin && rest.path.includes('/admin')) {
          return (<Redirect to={{ pathname: '/', state: { from: props.location } }} />);
        }
        return (<Component {...props} />);
      }}
    />
  );
};
