import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const getUserCard = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/users/card', { headers });
  return res.data;
};

export const attachDiscountToUser = async (id) => {
  const headers = getHeaders();
  await axios.put(`/api/users/discount/${id}`, {}, { headers });
};

export const unsubscribe = async (email64) => {
  const res = await axios.put(`/api/users/unsubscribe`, { email64 }, {});
  return res.data;
};
