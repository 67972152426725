import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { logout } from '../../api/auth';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import logo from '../../assets/images/mowpod-boost-logo.png';
import { getPartner } from '../../utils/utils';
import { DesktopMenu } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';

const { logo: partnerLogo } = getPartner();

const menuItems = {
  admin: [
    { path: '/admin', label: 'Users' },
    { path: '/admin/referrers', label: 'Referrers' },
    {
      label: 'Stats',
      subMenu: [
        { label: 'Revenue', path: '/admin/stats/revenue' },
        { label: 'Email', path: '#' },
        { label: 'Errors', path: '/admin/stats/errors' },
        { label: 'GA', path: '#' },
      ],
    },
    { path: '/admin/rss-hosts', label: 'Rss Hosts' },
  ],
  user: [{ path: '/campaigns', label: 'Campaigns' }],
};

export default function Header({ hideMenu }) {
  const history = useHistory();
  const { setUser } = useCurrentUser();
  const handleLogout = async () => {
    await logout();
    setUser(null);
    history.push('/login');
  };

  return (
    <header>
      <div className={cx({ 'login-header': hideMenu }, 'header', 'container')}>
        <img className="heder--logo" src={logo} alt="mowpod boost logotype" />
        {partnerLogo && (
          <img
            className="heder--logo heder--partnerLogo"
            src={partnerLogo}
            alt="mowpod boost logotype"
          />
        )}
        {!hideMenu && (
          <>
            <MobileMenu menuItems={menuItems} handleLogout={handleLogout} />
            <DesktopMenu menuItems={menuItems} handleLogout={handleLogout} />
          </>
        )}
      </div>
    </header>
  );
}
