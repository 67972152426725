import React, { useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
// import AOS from 'aos';
// import Rellax from 'rellax';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Aos from 'aos';

import './assets/styles/styles.css';
import { CurrentUserProvider } from './contexts/CurrentUserContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { PublicRoute } from './components/PublicRoute/PublicRoute';
import Login from './pages/Login';
import useScript from './hooks/useScript';
import Footer from './components/Footer/Footer';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPasswordForm';
import ResetPassword from './pages/ResetPassword';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import CampaignList from './pages/CampaignList';
import ThankYou from './pages/ThankYou';
import PodcastStats from './pages/PodcastStats';
import Admin from './pages/Admin/Admin';
import 'aos/dist/aos.css';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import Unsubscribe from './pages/Unsubscribe';
import { changeTitle } from './utils/utils';
import { SuggestionProvide } from './contexts/SuggestionContext';

function App() {
  useEffect(() => {
    changeTitle();
    Aos.init();
    setTimeout(() => {
      const preloader = document.getElementById('preloader');
      preloader.classList.remove('show-preloader');
    }, 1000);
  }, []);
  useScript('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
  useScript('https://code.jquery.com/jquery-3.6.0.min.js');
  useScript('https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js');
  return (
    <>
      <LoadingProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CurrentUserProvider>
              <Switch>
                <PublicRoute component={Login} path="/login" exact />
                <PrivateRoute component={CampaignList} path="/campaigns" exact />
                <PrivateRoute component={ThankYou} path="/thank-you" exact />
                <PrivateRoute component={PodcastStats} path="/stats/:campaignId" exact />
                <PrivateRoute component={Admin} path="/admin" />
                <PublicRoute component={Unsubscribe} isPublic path="/unsubscribe/:email64" exact />
                <PublicRoute component={ForgotPassword} path="/forgot-password" exact />
                <PublicRoute component={ResetPassword} isPublic path="/reset-password" exact />
                <SuggestionProvide>
                  <PublicRoute component={SignUp} isPublic path="/" exact />
                </SuggestionProvide>
              </Switch>
            </CurrentUserProvider>
          </ThemeProvider>
        </BrowserRouter>
        <Footer />
      </LoadingProvider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
