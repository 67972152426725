import React, { useContext, useReducer } from 'react';
import { suggestionsReducer } from '../reducers/suggestionsReducer';
import { getCompressText } from '../api/openai';
import { toast } from 'react-toastify';

const SuggestionContext = React.createContext();
export const useSuggestion = () => useContext(SuggestionContext);

const initialState = {
  titles: null,
  subtitles: null,
  loading: false,
  error: false,
};

const actions = {
  SET_TITLES: 'SET_TITLES',
  SET_SUBTITLES: 'SET_SUBTITLES',
  SET_LOADING: 'SET_LOADING',
  SET_ERROR: 'SET_ERROR',
};

export const SuggestionProvide = ({ children }) => {
  const [state, dispatch] = useReducer(suggestionsReducer, initialState);
  const getSuggestions = (text) => {
    dispatch({ type: actions.SET_LOADING, data: true });
    getCompressText({ searchQuery: text })
      .then((data) => {
        dispatch({ type: actions.SET_TITLES, data: data.options.titles });
        dispatch({ type: actions.SET_SUBTITLES, data: data.options.descriptions });
        dispatch({ type: actions.SET_ERROR, data: null });
        dispatch({ type: actions.SET_LOADING, data: false });
      })
      .catch((error) => {
        if (error && error.code === 'ERR_CANCELED') return;
        dispatch({ type: actions.SET_TITLES, data: null });
        dispatch({ type: actions.SET_SUBTITLES, data: null });
        dispatch({ type: actions.SET_ERROR, data: true });
        dispatch({ type: actions.SET_LOADING, data: false });
        toast.error(error.response.data.statusText);
      });
  };
  const value = {
    titles: state.titles,
    subtitles: state.subtitles,
    loading: state.loading,
    error: state.error,
    getSuggestions,
    setTitles: (data) => dispatch({ type: actions.SET_TITLES, data }),
    setSubtitles: (data) => dispatch({ type: actions.SET_SUBTITLES, data }),
    setLoading: (data) => dispatch({ type: actions.SET_LOADING, data }),
    setError: (data) => dispatch({ type: actions.SET_ERROR, data }),
  };

  return <SuggestionContext.Provider value={value}>{children}</SuggestionContext.Provider>;
};
