import React, { useState } from 'react';
import {
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { SubMenu } from './SubMenu';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#2a2a2a',
  },
  nested: {
    paddingLeft: 20,
    paddingRight: 60,
    color: '#fff',
  },
}));

export const MobileMenu = ({ menuItems, handleLogout }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useCurrentUser();
  const [openMenu, setOpenMenu] = useState(false);
  const userType = user?.admin ? 'admin' : 'user';
  return (
    <Hidden smUp>
      <IconButton
        className="header__logout"
        color="primary"
        aria-label="upload picture"
        onClick={() => setOpenMenu(true)}
      >
        <FontAwesomeIcon icon={faBars} style={{ color: '#ff812e' }} />
      </IconButton>
      <Drawer
        classes={{ paper: classes.root }}
        anchor="right"
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      >
        <List>
          {!!user ? (
            <>
              {menuItems[userType].map((item) => {
                return !!item.subMenu ? (
                  <SubMenu key={item.label} menuItem={item} type='mobile'>
                    <ListItem key={item.label} button className={classes.nested}>
                      <ListItemText>
                        <a onClick={() => history.push(item.path)}>{item.label}</a>
                      </ListItemText>
                    </ListItem>
                  </SubMenu>
                ) : (
                  <ListItem key={item.label} button className={classes.nested}>
                    <ListItemText>
                      <a onClick={() => history.push(item.path)}>{item.label}</a>
                    </ListItemText>
                  </ListItem>
                );
              })}
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <a onClick={handleLogout}>Logout</a>
                </ListItemText>
              </ListItem>
            </>
          ) : (
            <ListItem button className={classes.nested}>
              <ListItemText>
                <a onClick={() => history.push('/login')}>Login</a>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Drawer>
    </Hidden>
  );
};
