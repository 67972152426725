import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const options = {
  responsive: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true,
        color: '#fff',
      },
    },
  },
};

export default function TopDevices({ data, loading }) {
  const initialState = {
    labels: [],
    datasets: [{
      label: '',
      backgroundColor: [],
      data: [],
      hoverOffset: 4,
    }],
  };
  const [chartData, setChartData] = useState({ ...initialState });

  useEffect(() => {
    if (data.length) {
      const currentData = { ...initialState };
      data.forEach((item) => {
        currentData.labels.push(item.devicetype);
        currentData.datasets[0].data.push(item.imp);
        currentData.datasets[0].backgroundColor.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
      });
      setChartData(currentData);
    }
  }, [data]);

  return !loading ? (!!chartData.datasets[0].data.length && <Doughnut data={chartData} options={options} />) : (<CircularProgress />);
}
