import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { useLoading } from '../../contexts/LoadingContext';

export const PublicRoute = ({ component: Component, isPublic, ...rest }) => {
  const { user } = useCurrentUser();
  const { setLoading } = useLoading();
  useEffect(() => setLoading(true));
  const isLoggedIn = !!user;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && user.admin) {
          return (<Redirect to={{ pathname: '/admin', state: { from: props.location } }} />);
        }
        if (!isLoggedIn || isPublic) {
          return (<Component {...props} />);
        }
        return (<Redirect to={{ pathname: '/', state: { from: props.location } }} />);
      }}
    />
  );
};
