import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  wrapper: {
    paddingRight: 30,
  },
  title: {
    marginTop: 30,
  },
  list: {
    paddingTop: 30,
  },
  input: {
    border: '1px solid #fff',
    padding: '5px 15px',
    marginTop: 20,
    width: '100%',
  },
  btnWrapper: {
    marginTop: 30,
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));
