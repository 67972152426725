import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import CountUp from 'react-countup';
import { useStyles } from './styles';
import { getStats, getCampaignByIds, getDevices, getGeos, getLineItemById, getReport } from '../../api/stackadapt';
import TopDevices from './charts/TopDevices';
import TopCities from './TopCities';
import StatsTable from './StatsTable';

import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import Chart from './chart';
import { getCampaign } from '../../api/campaigns';
import Header from '../../components/Header/Header';
import Rellax from 'rellax';
import { getRssFeed } from '../../api/rss';
import { periodsForCampaignStats } from '../../utils/utils';
import AdExample from './AdExample';

const types = ['total', 'daily', 'hourly'];

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#000',
    color: '#fff',
  },
  body: {
    fontSize: 14,
    color: '#fff',
  },
}))(TableCell);

export default function PodcastStats() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const [campaignGroups, setCampaignGroups] = useState({});
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [type, setType] = useState('total');
  const { campaignId } = useParams();
  const [stats, setStats] = useState({});
  const [resource] = useState('line_item');
  const [campaigns, setCampaigns] = useState([]);
  const [retargetStat, setRetargetStat] = useState({});
  const [campRetarget, setCampRetarget] = useState([]);
  const [loading, setLoading] = useState(false);
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [campaignsLoading, setCampaignsLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [topDevices, setTopDevices] = useState([]);
  const [topCities, setTopCities] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);

  const getStatistics = (data) => {
    setLoading(true);
    getStats(data)
      .then((statsData) => {
        setStats(statsData);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    new Rellax('.rellax', {
      center: true,
    });
    setLoading(true);
    setStatesLoading(true);
    setDevicesLoading(true);
    setCampaignsLoading(true);
    if (campaignId) {
      getCampaign(campaignId).then((campaign) => {
        setCampaignGroups(campaign);
        if (campaign.episode.itunes.image) {
          setImg(campaign.episode.itunes.image);
        } else {
          getRssFeed(campaign.rss).then(({ items, ...rest }) => {
            setImg(rest.image.url);
          });
        }
        getLineItemById({ id: campaign.campaignGroupsId })
          .then((line_item) => {
            return line_item.all_campaign_ids;
          })
          .then((allCampaignIds) => {
            getCampaignByIds({ ids: [...allCampaignIds] }).then((result) => {
              setCampRetarget(
                result.filter((item) => item.name.toLowerCase().includes('retarget')).map((i) => i.id)
              );
              setCampaigns(result);
              setCampaignsLoading(false);
            });
          });
      });
    }
  }, []);

  useEffect(() => {
    if (campRetarget.length) {
      getStats({ resource: 'campaign', type, id: campRetarget, startDate, endDate }).then((statsData) => {
        setRetargetStat(statsData);
      });
    }
  }, [startDate, endDate, campRetarget]);

  useEffect(() => {
    if (campaignGroups.campaignGroupsId) {
      getStatistics({ resource, type, id: campaignGroups.campaignGroupsId, startDate, endDate });
      getDevices({ resource: 'line_item', type: 'devices', ids: campaignGroups.campaignGroupsId })
        .then((result) => {
          setTopDevices(result);
          setDevicesLoading(false);
        })
        .catch(() => {
          setTopDevices([]);
          setDevicesLoading(false);
        });
      getGeos({
        resource: 'line_item',
        ids: campaignGroups.campaignGroupsId,
      })
        .then((result) => {
          setTopCities(result);
          setStatesLoading(false);
        })
        .catch(() => {
          setTopCities([]);
          setStatesLoading(false);
        });
    }
  }, [type, startDate, endDate, campaignGroups]);

  const handleStartDateChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    setStartDate(date);
    setOpenStartDate(false);
    setOpenEndDate(true);
  };

  const downloadReport = () => {
    setLoadingReport(true);
    getReport(campaignGroups.campaignGroupsId)
      .then((response) => {
        setLoadingReport(false);
        let url = window.URL.createObjectURL(new Blob([response]));
        let a = document.createElement('a');
        a.href = url;
        a.download = `report_${campaignGroups.title.replace(/[^a-zA-Z]/g, '_').trim()}_mowpod.xlsx`;
        a.click();
      })
      .catch(() => setLoadingReport(false));
  };

  const handleEndDateChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    setEndDate(date);
    setOpenEndDate(false);
  };

  const handleType = (event) => {
    const { value } = event.target;
    setType(value);
  };

  const setDateInterval = ({ start, end }) => {
    setStartDate(() => start);
    setEndDate(() => end);
  };

  return (
    <>
      <div className="backgrounds">
        <div className="background__rainbow">
          <div className="background__rainbow--image rainbow--first rellax" />
          <div className="background__rainbow--image rainbow--second rellax" />
          <div className="background__rainbow--image rainbow--third rellax" />
        </div>
        <div className="background-sun" />
      </div>
      <Header />
      <div className={`main container ${classes.container}`}>
        <Grid
          container
          direction="row"
          item
          md={12}
          className={classes.wrapper}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* Podcast Name */}
          <Grid className={classes.wrapperTitle} container direction="row" item md={6}>
            <Typography className={classes.title} variant="h5">
              Podcast: {campaignGroups.title}
            </Typography>
          </Grid>
          <Grid
        className={classes.wrapperTitle}
        container
        item
        md={6}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          variant="contained"
          disabled={loadingReport}
          onClick={downloadReport}
          className={cx(classes.button, {
            [classes.gray]: loadingReport,
          })}
        >
          PRINT REPORT
        </Button>
      </Grid>
          <Grid
            style={{ paddingRight: 15 }}
            container
            direction="row"
            item
            md={7}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {/* Podcast Img, Periods */}
            <Grid
              className={classes.wrapperStats}
              container
              direction="row"
              item
              md={12}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                className={classes.imgSection}
                item
                md={3}
                justifyContent="flex-start"
                alignItems="center"
              >
                {img && <img src={img} style={{ width: '100%' }} alt={campaignGroups.podcastName} />}
              </Grid>
              <Grid container direction="row" item md={9} justifyContent="space-between" alignItems="center">
                <Grid
                  container
                  direction="row"
                  item
                  md={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    className={classes.datePicker}
                    direction="row"
                    item
                    md={5}
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid container direction="row" item xs={3} alignItems="center">
                      <Typography style={{ color: '#000', fontSize: 10, fontWeight: 'bold' }}>
                        From
                      </Typography>
                    </Grid>
                    <Grid container direction="row" item xs={9} alignItems="center">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          variant="inline"
                          format="MM-dd-yyyy"
                          open={openStartDate}
                          maxDate={endDate}
                          onClose={() => setOpenStartDate(false)}
                          value={startDate}
                          onChange={handleStartDateChange}
                          InputProps={{
                            disableUnderline: true,
                            onClick: () => setOpenStartDate(true),
                            classes: { input: classes.inputDatePicker },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={classes.datePicker}
                    direction="row"
                    item
                    md={5}
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid container direction="row" item xs={3} alignItems="center">
                      <Typography style={{ color: '#000', fontSize: 10, fontWeight: 'bold' }}>To</Typography>
                    </Grid>
                    <Grid container direction="row" item xs={9} alignItems="center">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          variant="inline"
                          format="MM-dd-yyyy"
                          open={openEndDate}
                          minDate={startDate}
                          onClose={() => setOpenEndDate(false)}
                          value={endDate}
                          onChange={handleEndDateChange}
                          InputProps={{
                            disableUnderline: true,
                            onClick: () => setOpenStartDate(true),
                            classes: { input: classes.inputDatePicker },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  style={{ marginTop: 15 }}
                  item
                  md={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {periodsForCampaignStats.map((item, i) => {
                    const { start, end } = item.getTimeInterval();
                    const active = endDate === end && startDate === start;
                    return (
                      <Grid container direction="row" item md={4} justifyContent="center" alignItems="center">
                        <Button
                          key={i}
                          variant="contained"
                          className={cx(classes.periodBtn, { active })}
                          type="button"
                          onClick={() => setDateInterval(item.getTimeInterval())}
                        >
                          {item.label}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            {/* Chart */}
            <Grid
              className={classes.wrapperStats}
              container
              direction="row"
              item
              md={12}
              justifyContent="space-around"
              alignItems="flex-end"
            >
              <Chart totalStats={stats.total_stats || {}} retargetStat={retargetStat.total_stats || {}} />
            </Grid>
            {/* Stats Table */}
            <Grid
              className={classes.wrapperStats}
              style={{ marginBottom: 0 }}
              container
              direction="row"
              item
              md={12}
              justifyContent="flex-end"
              alignItems="flex-start"
            >
              <Grid
                style={{ marginBottom: 5 }}
                container
                direction="row"
                item
                md={3}
                justifyContent="flex-end"
                alignItems="center"
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select value={type} onChange={handleType}>
                    {types.map((item, i) => (
                      <MenuItem key={`${item}-${i}`} c value={item} className={classes.menuItem}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <StatsTable loading={loading} stats={stats.daily_stats} />
            </Grid>
            {stats.total_stats && (
              <Grid
                className={classes.wrapperStats}
                style={{ backgroundColor: '#000', borderRadius: '0 0 15px 15px' }}
                container
                direction="row"
                item
                md={12}
                justifyContent="space-between"
                alignItems="center"
              >
                <Table className={classes.tTable}>
                  <TableBody>
                    <TableRow>
                      <StyledTableCell className={classes.tBodyItem}>
                        <b>Total</b>
                      </StyledTableCell>
                      <StyledTableCell align="right" className={classes.tBodyItem}>
                        <b>
                          <CountUp end={stats.total_stats.imp} duration={2} separator="," />
                        </b>
                      </StyledTableCell>
                      <StyledTableCell align="right" className={classes.tBodyItem}>
                        <b>
                          <CountUp end={stats.total_stats.click} duration={2} separator="," />
                        </b>
                      </StyledTableCell>
                      <StyledTableCell align="right" className={classes.tBodyItem}>
                        <b>
                          <CountUp
                            end={stats.total_stats.ctr}
                            duration={2}
                            separator=","
                            decimals={3}
                            decimal="."
                          />
                        </b>
                      </StyledTableCell>
                      <StyledTableCell align="right" className={classes.tBodyItem}>
                        <b>
                          <CountUp end={stats.total_stats.conv_click} duration={2} separator="," />
                        </b>
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" item md={5} justifyContent="flex-start" alignItems="flex-start">
            {/* Top cities */}
            <Grid
              container
              className={classes.wrapperStats}
              direction="row"
              item
              md={12}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                style={{ marginBottom: 20 }}
                container
                direction="row"
                item
                md={12}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography style={{ fontSize: 26, color: '#f9a119', fontWeight: 'bold' }}>
                  Top 10 States
                </Typography>
              </Grid>
              <TopCities data={topCities} loading={statesLoading} />
            </Grid>
            {/* Top Device Top OS */}
            <Grid
              container
              className={classes.wrapperStats}
              direction="row"
              item
              md={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography style={{ color: '#4faa30', fontSize: 26, fontWeight: 'bold', marginBottom: 15 }}>
                TOP Devices
              </Typography>
              <Grid container direction="row" item md={12} justifyContent="center" alignItems="center">
                <TopDevices data={topDevices} loading={devicesLoading} />
              </Grid>
            </Grid>
            {/* Ad Example */}
            <Grid
              container
              className={classes.wrapperStats}
              direction="row"
              item
              md={12}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography style={{ color: '#f56600', fontSize: 26, fontWeight: 'bold', marginBottom: 15 }}>
                Ad
              </Typography>
              <Grid container direction="row" item md={12} justifyContent="center" alignItems="center">
                <AdExample campaign={campaigns[0]} loading={campaignsLoading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
