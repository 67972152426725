import Slider from 'rc-slider';

export const RangeSlider = ({ min, max, onChange }) => {
  return (
    <Slider
      min={min}
      max={max}
      onChange={onChange}
      styles={{ track: { background: 'transparent' } }}
    />
  );
};
