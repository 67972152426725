import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';

import { useStyles } from './styles';
import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import { periodsForUserStats } from '../../utils/utils';
import { Button, Grid, Typography, CircularProgress } from '@material-ui/core';
import Chart from './charts/Chart';
import { getRevenue } from '../../api/stats';
import { initialOptions } from './charts/initialData';

export default function Revenue() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const [startDate, setStartDate] = useState(
    moment().subtract(6, 'month').startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [revenue, setRevenue] = useState();
  const [dateType, setDateType] = useState('month');
  const [period, setPeriod] = useState('last6Months');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRevenue({ startDate, endDate, dateType }).then((result) => {
      setRevenue(result);
      setLoading(false);
    });
  }, [period]);

  const setDateInterval = ({ start, end, dateType, value }) => {
    setStartDate(() => start);
    setEndDate(() => end);
    setDateType(dateType);
    setPeriod(value);
  };

  return (
    <div className={`main container ${classes.container}`}>
      <Grid
        container
        direction="row"
        item
        md={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>Revenue</Typography>
        <Grid
          container
          direction="row"
          style={{ marginTop: 15 }}
          item
          md={12}
          justifyContent="space-between"
          alignItems="center"
        >
          {periodsForUserStats.map((item, i) => {
            const active = period === item.value;
            return (
              <Grid
                container
                key={i}
                direction="row"
                item
                md={3}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  className={cx(classes.periodBtn, { active })}
                  type="button"
                  onClick={() =>
                    setDateInterval({
                      ...item.getTimeInterval(),
                      dateType: item.dateType,
                      value: item.value,
                    })
                  }
                >
                  {item.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          container
          direction="row"
          style={{ marginTop: 15 }}
          item
          md={12}
          justifyContent="center"
          alignItems="center"
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Chart data={revenue} dateType={dateType} customOptions={{ ...initialOptions }} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
