import { makeStyles } from '@material-ui/core';
import Minus from '../../assets/images/icons/dash.svg';

export const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: 1340,
  },
  wrapper: {
    padding: '35px 25px !important',
  },
  wrapperTitle: {
    width: '100%',
    marginBottom: 30,
  },
  wrapperStats: {
    padding: 20,
    marginBottom: 15,
    backgroundColor: '#1c1b1b',
    borderRadius: 10,
  },
  imgSection: {
    paddingRight: 15,
    paddingBottom: 15,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    },
  },
  title: { fontSize: 26, fontWeight: 'bold', marginBottom: 15 },
  root: {
    padding: '0 20px 0 0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  indeterminate: {
    backgroundColor: '#fff',
    backgroundImage: `url(${Minus})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    'input:hover ~ &': {
      backgroundColor: '#f0f0f0f7 ',
    },
  },
  datePicker: {
    marginTop: 5,
    color: '#000',
    backgroundColor: '#fff',
    borderRadius: 20,
    padding: '4px 15px',
  },
  inputDatePicker: {
    color: '#000',
    width: '100%',
    borderRadius: 8,
    fontWeight: 'bold',
    // backgroundColor: '#fff',
    // border: '1px solid',
    textAlign: 'right',
    '&:focus': {
      outline: 'none',
    },
    // padding: '10px 15px',
  },
  chart: {
    color: '#fff',
  },
  link: {
    color: '#fff',
  },
  tHead: {
    fontWeight: 'bold',
    backgroundColor: theme,
  },
  tTableContainer: {
    background: 'transparent',
    boxShadow: 'none',
  },
  tHeader: {
    color: 'grey',
    border: 'none',
  },
  tTable: {
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
  },
  tTablePagination: {
    color: '#fff',
    border: 'none',
  },
  tFirstItem: {
    border: 'none',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  tBodyItem: {
    border: 'none',
  },
  iconButton: {
    color: '#fff',
  },
  formControl: {
    width: '100%',
    marginBottom: 15,
  },
  periodBtn: {
    margin: '10px 5px 5px',
    width: '100%',
    fontSize: 12,
    borderRadius: 10,
    backgroundColor: '#515151',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#515151',
    },
    '&.active': {
      backgroundColor: '#f56600',
      color: '#000',
    },
  },
  button: {
    cursor: 'pointer',
    width: 150,
    height: 50,
    backgroundImage: props => `url("${props.bgBtn}")`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: ' rgba(255, 255, 128, 0)',
    backgroundRepeat: 'no-repeat',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',

    paddingTop: 15,
    fontFamily: '"Gotham Pro", sans-serif',
    fontSize: 14,
    fontWeight: '800',

    color: 'white',
    textTransform: 'capitalize',

    '-webkit-transition': 'ease 0.3s',
    '-moz-transition': 'ease 0.3s',
    transition: 'ease 0.3s',

    '&:hover': {
      filter: 'brightness(110%)',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: ' rgba(255, 255, 128, 0)',
      boxShadow: 'none',
    },
  },
  gray: {
    background: props => `url("${props.bgBtnGray}") no-repeat`,
  },
  chartSection: {
    backgroundSize: 'inherit',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    alignSelf: 'stretch',
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(0.8)',
    },
  },
  impChartSection: {
    backgroundImage: props => `url(${props.impSvg})`,
  },
  clkChartSection: {
    backgroundImage: props => `url(${props.clkSvg})`,
  },
  dwnldChartSection: {
    position: 'relative',
    '&:after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: props => `url(${props.subsection}) 50% 20% no-repeat`,
      transform: 'rotate(-2deg)',
    },
    '&:before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: props => `url(${props.dwnSvg}) bottom no-repeat`,
    },
  },
  chartData: {
    fontWeight: 'bold',
    zIndex: 1,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      fontSize: '12px !important',
      padding: '0 5px',
      overflowWrap: 'break-word',
      lineHeight: '14px',
      textAlign: 'center',
    },
  },
  chartXS: {
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
  },
  chartDataImp: {
    paddingBottom: 10,
    fontSize: 20,
  },
  chartDataClk: {
    paddingBottom: 35,
    fontSize: 24,
  },
  chartDataDwn: {
    paddingBottom: 35,
    fontSize: 32,
  },
  targetDwnlds: {
    padding: '70px 34px 7% 22px',
    fontSize: 14,
    transform: 'rotate(-14deg)',
  },
  repeatListener: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 700,
  },
  tableCell: {
    textAlign: 'center',
    color: 'white',
    fontSize: 14,
  },
  tableCellDate: {
    minWidth: 100,
  },
  tableCellEmail: {
    minWidth: 180,
  },
  tableCellGreyBackGround: {
    backgroundColor: 'rgba(128, 128, 128, 0.1)',
  },
  whiteText: {
    color: 'white',
  },
  totalCell: {
    color: '#f56600',
    fontWeight: 700,
  },
  errorsTableContainer: {
    position: 'relative',
    margin: '10px auto',
    color: 'white',
    width: '95vw',
    left: '50%',
    right: 0,
    transform: 'translateX(-50%)',
    maxWidth: 'max-content',
    [theme.breakpoints.down('xs')]: {
      width: '85vw',
      transform: 'translateX(-52.5%)',
    },
  },
  errorsTableContainerContent: {
    overflowX: 'auto',
    maxWidth: '100%',
    backdropFilter: 'blur(0px)',
  },
}));
