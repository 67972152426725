import React, { useState } from 'react';
import { Button, Grid, CircularProgress, Typography } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import cx from 'classnames';

import { useStyles } from './styles';
import 'react-datepicker/dist/react-datepicker.css';
import { getSourceReport } from '../../api/campaigns';

export default function SourceReport() {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(moment().startOf('M').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('M').toDate());
  const [loading, setLoading] = useState(false);
  const renderMonthContent = (month, shortMonth, longMonth) => {
    return <span>{shortMonth}</span>;
  };

  const hendleMonth = (a) => {
    setStartDate(moment(a).startOf('M').toDate());
    setEndDate(moment(a).endOf('M').toDate());
  };

  const handleClick = async () => {
    setLoading(true);
    const blob = await getSourceReport({ startDate, endDate });
    setLoading(false);
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `source-report-${moment(startDate).format('YYYY-MM-DD')}_${moment(endDate).format(
        'YYYY-MM-DD'
      )}.xlsx`
    );
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <div className={`main container ${classes.container}`}>
      <Grid
        container
        item
        md={12}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid container item md={12} justifyContent="flex-start" alignItems="center">
          <Typography className={classes.title}>Source Report</Typography>
        </Grid>
        <Grid container item md={5} justifyContent="center" alignItems="center">
          <DatePicker
            selected={startDate}
            onChange={hendleMonth}
            renderMonthContent={renderMonthContent}
            popperPlacement="bottom"
            showMonthYearPicker
            dateFormat="MMM yyyy"
          />
        </Grid>
        <Grid container item md={5} justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            className={cx(classes.periodBtn, { active: !loading })}
            type="button"
            onClick={handleClick}
          >
            {loading ? <CircularProgress size={20} /> : 'GET SELF SERVE REPORT'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
