import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import cx from 'classnames';
import { validationSchema } from './validationSchema';
import { resetPassword } from '../../api/auth';
import logo from '../../assets/images/mowpod-boost-logo.png';
import Rellax from 'rellax';
import { toast } from 'react-toastify';

export default function ResetPassword() {
  const location = useLocation();
  const history = useHistory();
  const [isReseted, setIsReseted] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    new Rellax(".rellax", {
      center: true,
    });
  }, [])
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const queryParams = new URLSearchParams(location.search);
      resetPassword({ ...values, token: queryParams.get('token') })
        .then(() => {
          setIsReseted(true);
          setTimeout(() => {
            history.push('/login');
          }, 2000);
        })
        .catch((err) => {
          if (err.response.data) {
            formik.setFieldError('email', err.response.data.message);
            toast.error(err.response.data.message);
          }
        });
    },
  });

  return (
    <>
      <div className="backgrounds">
        <div className="background__rainbow">
          <div className={cx("background__rainbow--image rainbow--first login--rainbow rellax")} />
        </div>
        <div className="background-sun background-sun--login" />
      </div>
      <header>
        <div className="login-header">
          <img className="heder--logo" src={logo} alt="mowpod boost logotype" />
        </div>
      </header>
      <main className="main">
        <div className="main__login container" data-aos="fade-up" data-aos-delay="400" data-aos-duration="400">
          <FormikProvider value={formik}>
            <form className="login-form" onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="main__login--login input-container">
                <div>
                  <label className="orange-text" htmlFor="login-form">New Password</label>
                </div>
                <Field type="password" name="password" id="login-form" placeholder="New Password" />
                <ErrorMessage name="password">{msg => <div className='fieldError errorColor'>{msg}</div>}</ErrorMessage>
              </div>
              <div className="main__login--password input-container">
                <div>
                  <label className="orange-text" htmlFor="login-form-password">Confirm New Password</label>
                </div>
                <Field type="password" name="confirmPassword" minLength="6" placeholder="Confirm New Password" id="login-form-password" />
                <ErrorMessage name="confirmPassword">{msg => <div className='fieldError errorColor'>{msg}</div>}</ErrorMessage>
              </div>
              <button className="submit-button" type="submit">Submit</button>
            </form>
          </FormikProvider>
        </div>
      </main>
    </>
  );
}
