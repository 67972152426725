import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const createReferrer = async (params) => {
  const headers = getHeaders();
  const res = await axios.post('/api/referrers', params, { headers });
  return res.data;
};

export const updateReferrer = async (id, params) => {
  const headers = getHeaders();
  const res = await axios.put(`/api/referrers/${id}`, params, { headers });
  return res.data;
};

export const getReferrer = async (id) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/referrers/${id}`, { headers });
  return res.data;
};

export const getReferrers = async () => {
  const headers = getHeaders();
  const res = await axios.get(`/api/referrers`, { headers });
  return res.data;
}

export const deleteReferrer = async (id) => {
  const headers = getHeaders();
  const res = await axios.delete(`/api/referrers/${id}`, { headers });
  return res.data;
};