import React, { useEffect, useState } from 'react';
import moment from 'moment';
import cx from 'classnames';
import _ from 'lodash';

import { useStyles } from './styles';
import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import { Grid, Typography, CircularProgress, Button } from '@material-ui/core';
import Chart from './charts/Chart';
import { getMostPopularDays } from '../../api/stats';
import { numberWithCommas, periodsForReferrersStats } from '../../utils/utils';

export default function MostPopularDays() {
  const classes = useStyles({ bgBtn, bgBtnGray });
  const [chartData, setChartData] = useState();
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('thisMonth');
  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx) => {
            const totalBoost = ctx.dataset.totalBoosts[ctx.dataIndex];
            return [`Number of Campaigns: ${ctx.formattedValue}`, `Spent: $${totalBoost}`];
          },
        },
      },
      datalabels: {
        color: '#fff',
        anchor: 'end',
        align: 'top',
        clamp: true,
        formatter: function (value) {
          return `${numberWithCommas(value)}`;
        },
      },
      legend: {
        display: true,
        labels: {
          color: 'white',
        },
      },
    },
    scales: {
      y: {
        grid: {
          borderColor: '#515151',
          tickColor: '#515151',
          color: '#515151',
        },
        grace: '5%',
        ticks: {
          color: '#fff',
          callback: function (value) {
            return `${numberWithCommas(value)}`;
          },
        },
        title: {
          align: 'end',
          display: true,
          text: 'Number of Campaigns',
          color: '#fff',
        },
      },
      x: {
        grid: {
          borderColor: '#515151',
          tickColor: '#515151',
          color: '#515151',
        },
        ticks: {
          color: '#fff',
          padding: 10,
        },
        title: {
          align: 'end',
          display: true,
          color: '#fff',
          text: 'Days of Week',
        },
      },
    },
  };

  useEffect(() => {
    setLoading(true);
    setChartData();
    getMostPopularDays({ startDate, endDate }).then((result) => {
      setChartData(result);
      setLoading(false);
    });
  }, [period]);

  const setDateInterval = ({ start, end, value }) => {
    setStartDate(() => start);
    setEndDate(() => end);
    setPeriod(() => value);
  };

  return (
    <div className={`main container ${classes.container}`}>
      <Grid
        container
        direction="row"
        item
        md={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography className={classes.title}>The Most Popular Days</Typography>
        <Grid
          container
          direction="row"
          style={{ marginTop: 15 }}
          item
          md={12}
          justifyContent="space-between"
          alignItems="center"
        >
          {periodsForReferrersStats.map((item, i) => {
            const active = period === item.value;
            return (
              <Grid
                container
                key={i}
                direction="row"
                item
                md={3}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  className={cx(classes.periodBtn, { active })}
                  type="button"
                  onClick={() => setDateInterval({ ...item.getTimeInterval(), value: item.value })}
                >
                  {item.label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <Grid
          container
          direction="row"
          style={{ marginTop: 15 }}
          item
          md={12}
          justifyContent="center"
          alignItems="center"
        >
          {loading || !chartData ? (
            <CircularProgress />
          ) : (
            <Chart data={chartData} customOptions={options} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
