import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import cx from 'classnames';
import CountUp from 'react-countup';
import impSvg from '../../assets/images/impessions.svg';
import clkSvg from '../../assets/images/clicks.svg';
import dwnSvg from '../../assets/images/downloads.svg';
import dwnSectionSvg from '../../assets/images/downloads.png';

import { useStyles } from './styles';

export default function Chart({ totalStats: { imp = 0, click = 0, conv_click = 0 } = {}, retargetStat: { conv_click: targetDwnlds = 0 } = {} }) {
  const classes = useStyles({
    impSvg, clkSvg, dwnSvg: (targetDwnlds ? dwnSectionSvg : dwnSvg),
  });
  return (
    <Grid container direction="row" className={classes.chartXS} item md={12} justifyContent="space-around" alignItems="flex-end">
      <Grid className={cx(classes.impChartSection, classes.chartSection)} container direction="column" item xs={4} justifyContent="flex-end" alignItems="center">
        <Typography className={cx(classes.chartData, classes.chartDataImp)}>
          <CountUp
            end={imp}
            duration={2}
            separator=","
          />
        </Typography>
        <Typography className={classes.chartData} paragraph>Impressions</Typography>
      </Grid>
      <Grid className={cx(classes.clkChartSection, classes.chartSection)} container direction="column" item xs={4} justifyContent="flex-end" alignItems="center">
        <Typography className={cx(classes.chartData, classes.chartDataClk)}>
          <CountUp
            end={click}
            duration={2}
            separator=","
          />
        </Typography>
        <Typography className={classes.chartData} paragraph>Plays</Typography>
      </Grid>
      <Grid className={cx(classes.dwnldChartSection, classes.chartSection)} container direction="column" item xs={4} justifyContent="flex-end" alignItems="center">
        <Grid className={cx(classes.chartData, classes.targetDwnlds)} container justifyContent="center" alignItems="center">
          {!!targetDwnlds && (
            <>
              <CountUp
                className={classes.repeatListener}
                end={targetDwnlds}
                duration={1}
                separator=","
              />
              <Typography className={classes.repeatListener}>&nbsp;repeat</Typography>
              <Typography className={classes.repeatListener}>&nbsp;listeners</Typography>
            </>
          )}
        </Grid>
        <Typography className={cx(classes.chartData, classes.chartDataDwn)}>
          <CountUp
            end={conv_click}
            duration={2}
            separator=","
          />
        </Typography>
        <Typography className={classes.chartData}>Total</Typography>
        <Typography className={classes.chartData}>IAB 2.0</Typography>
        <Typography className={classes.chartData} paragraph>Downloads</Typography>
      </Grid>
    </Grid>
  );
}
