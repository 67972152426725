import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { AGE, GENDER, GEO, HOUSEHOLD_INCOME } from '../../utils/consts';

import { useStyles } from './styles';

export default function Advanced({ formik }) {
  const classes = useStyles();
  const handleGeo = (e) => {
    const { value, checked } = e.target;
    const geos = [...formik.values.geos];
    if (checked) {
      if (geos.includes('ALL')) {
        return false;
      }
      return formik.setFieldValue('geos', value !== 'ALL' ? [...geos, value] : [value]);
    }
    const index = geos.indexOf(value);
    if (index > -1) {
      geos.splice(index, 1);
    }
    return formik.setFieldValue('geos', geos);
  };

  const handleDemos = (e) => {
    const { value, checked } = e.target;
    const demos = [...formik.values.demos];
    if (checked) {
      return formik.setFieldValue('demos', [...demos, value]);
    }
    const index = demos.indexOf(value);
    if (index > -1) {
      demos.splice(index, 1);
    }
    return formik.setFieldValue('demos', demos);
  };
  return (
    <>
      <Accordion className={classes.accordionBG} elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          classes={{ root: classes.accordionSummaryRoot }}
          style={{ fontSize: 28, padding: 0}}
        >
          <label className="orange-text">Advanced</label>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="row"
            className={classes.inputSection}
            item
            md={12}
            justify-content="flex-start"
            alignItems="flex-start"
          >
            <Grid
              container
              direction="row"
              className={classes.inputSection}
              item
              md={12}
              justify-content="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="h5">Geographic Targeting</Typography>
            </Grid>
            <Grid container direction="row" className={classes.inputSection} item md={12}>
              {GEO.map((props, i) => (
                <Grid direction="row" key={`${props.value}${i}`} container item md={3}>
                  <FormControlLabel
                    {...props}
                    checked={formik.values.geos.includes(props.value)}
                    classes={{ label: classes.checkBoxLabel }}
                    control={<Checkbox color="primary" />}
                    onChange={handleGeo}
                    labelPlacement="end"
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              direction="row"
              className={classes.inputSection}
              item
              md={12}
              justify-content="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="h5">Demographic Targeting</Typography>
            </Grid>
            <Grid
              container
              direction="row"
              item
              md={12}
              justify-content="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="h6">Gender</Typography>
            </Grid>
            <Grid container direction="row" className={classes.inputSection} item md={12}>
              {GENDER.map((props, i) => (
                <Grid direction="row" key={`${props.value}${i}`} container item md={3}>
                  <FormControlLabel
                    {...props}
                    checked={formik.values.demos.includes(props.value)}
                    classes={{ label: classes.checkBoxLabel }}
                    control={<Checkbox color="primary" />}
                    onChange={handleDemos}
                    labelPlacement="end"
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              direction="row"
              item
              md={12}
              justify-content="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="h6">Age</Typography>
            </Grid>
            <Grid container direction="row" className={classes.inputSection} item md={12}>
              {AGE.map((props, i) => (
                <Grid direction="row" key={`${props.value}${i}`} container item md={3}>
                  <FormControlLabel
                    {...props}
                    checked={formik.values.demos.includes(props.value)}
                    classes={{ label: classes.checkBoxLabel }}
                    control={<Checkbox color="primary" />}
                    onChange={handleDemos}
                    labelPlacement="end"
                  />
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              direction="row"
              item
              md={12}
              justify-content="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="h6">Household Income</Typography>
            </Grid>
            <Grid container direction="row" className={classes.inputSection} item md={12}>
              {HOUSEHOLD_INCOME.map((props, i) => (
                <Grid direction="row" key={`${props.value}${i}`} container item md={3}>
                  <FormControlLabel
                    {...props}
                    checked={formik.values.demos.includes(props.value)}
                    classes={{ label: classes.checkBoxLabel }}
                    control={<Checkbox color="primary" />}
                    onChange={handleDemos}
                    labelPlacement="end"
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
