import {
  CircularProgress, Grid, Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

export default function TopCities({ data, loading }) {
  const [topCities, setTopCities] = useState([]);

  useEffect(() => {
    if (data) {
      setTopCities(data);
    }
  }, [data]);

  const renderTopCities = () => {
    let top;
    return topCities.map((item, i) => {
      if (i === 0) {
        top = item.conv_click;
      }
      const value = Math.round((item.conv_click / top) * 100);
      return (
        <Grid key={i} style={{ paddingBottom: 15 }} container direction="row" item md={12} justifyContent="flex-start" alignItems="center">
          <Grid style={{ paddingRight: 15 }} container direction="row" item md={4} justifyContent="flex-start" alignItems="center">
            <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{item.region}</Typography>
          </Grid>
          <Grid container direction="row" item md={5} justifyContent="flex-start" alignItems="center">
            <div style={{
              width: `${value}%`, height: 10, backgroundColor: '#f9a119', borderRadius: 7, opacity: value / 100,
            }}
            />
          </Grid>
          <Grid container direction="row" item md={3} justifyContent="flex-end" alignItems="center">
            <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{item.conv_click}</Typography>
          </Grid>
        </Grid>
      );
    });
  };
  return loading ? (
    <Grid style={{ paddingBottom: 15 }} container direction="row" item md={12} justifyContent="center" alignItems="center">
      <CircularProgress />
    </Grid>
  ) : renderTopCities();
}
