import * as yup from 'yup';

function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}

yup.addMethod(yup.string, 'equalTo', equalTo);

export const validationSchema = yup.object().shape({
  password: yup.string().required('New Password is required').min(8, 'Must Contain 8 Characters').max(16).matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
  ),
  confirmPassword: yup.string().required('Confirm New Password is required').equalTo(yup.ref('password'), 'Passwords do not match'),
});
