import {
  CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles,
} from '@material-ui/core';
import React from 'react';
import CountUp from 'react-countup';

import { useStyles } from './styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: '#fff',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#1C1B1B',
      color: '#fff',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#262424',
      color: '#fff',
    },
  },
}))(TableRow);

export default function StatsTable({ loading, stats }) {
  const classes = useStyles();
  return (
    <TableContainer className={classes.tTableContainer} style={{ maxHeight: 400 }}>
      <Table className={classes.tTable}>
        <TableHead className={classes.tHead}>
          <TableRow style={{ border: 'none' }}>
            <TableCell className={classes.tHeader}>Date</TableCell>
            <TableCell className={classes.tHeader} align="right">Impression</TableCell>
            <TableCell className={classes.tHeader} align="right">Plays</TableCell>
            <TableCell className={classes.tHeader} align="right">Play Rate</TableCell>
            <TableCell className={classes.tHeader} align="right">IAB Downloads</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={5} align="center"> (<CircularProgress />) </TableCell>
            </TableRow>
          ) : (
            stats && stats.map((item, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row" className={classes.tFirstItem}>
                  {item.date}
                </StyledTableCell>
                <StyledTableCell align="right" className={classes.tBodyItem}>
                  <CountUp
                    end={item.imp}
                    duration={2}
                    separator=","
                  />
                </StyledTableCell>
                <StyledTableCell align="right" className={classes.tBodyItem}>
                  <CountUp
                    end={item.click}
                    duration={2}
                    separator=","
                  />
                </StyledTableCell>
                <StyledTableCell align="right" className={classes.tBodyItem}>
                  <CountUp
                    end={item.ctr}
                    duration={2}
                    separator=","
                    decimals={2}
                    decimal="."
                    suffix='%'
                  />
                </StyledTableCell>
                <StyledTableCell align="right" className={classes.tBodyItem}>
                  <CountUp
                    end={item.conv_click}
                    duration={2}
                    separator=","
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
