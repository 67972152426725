import React, { useEffect } from 'react';

import Revenue from './Revenue';
import FirstTimeCampaigns from './FirstTimeCampaigns';
import ReferrersStats from './ReferrersStats';
import RssStats from './RssStats';
import MostPopularDays from './MostPopularDays';
import DailyRevenue from './DailyRevenue';
import SourceReport from './SourceReport';

export default function UserStats() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <>
      <Revenue />
      <FirstTimeCampaigns />
      <ReferrersStats />
      <RssStats />
      <MostPopularDays />
      <DailyRevenue />
      <SourceReport />
    </>
  );
}