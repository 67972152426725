import { useEffect, useState } from 'react';

export const useSearch = (initialData, key) => {
  const [data, setData] = useState(initialData);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const searchItems = () => {
      const normalizedSearchValue = searchValue.toLowerCase();
      const filteredData = initialData.filter(
        item => item[key]?.toLowerCase().includes(normalizedSearchValue) || item.isNew
      );
      setData(filteredData);
    };

    searchItems();
  }, [searchValue, initialData, key]);

  return {
    data,
    searchValue,
    setSearchValue,
  };
};
