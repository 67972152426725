import React, { useContext, useEffect, useState } from 'react';

const LoadingContext = React.createContext();
export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  return (
    <LoadingContext.Provider value={{
      loading,
      setLoading,
    }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
