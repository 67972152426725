import mowmediaLogo from '../../assets/images/mowmedia.png';
import Popup from 'reactjs-popup';
import TermsOfUse from './TermsOfUse';

// import 'reactjs-popup/dist/index.css';

const Modal = () => (
  <Popup
    trigger={open => (
      <button className='temrs-of-use'>TERMS OF USE</button>
    )}
    position="center center"
    modal
    closeOnDocumentClick
    lockScroll
  >
    {close => (
      <>
        <button className="close" onClick={close}>
          &times;
        </button>
        <TermsOfUse />
      </>
    )}
  </Popup>
);

export default function Footer(params) {
  return (
    <>
      <div className="d-line" />
      <footer className="footer--login">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-md-11">
                  <div className="row gx-5 gy-4">
                    <div className="col-lg-3">
                      <div className="logo">
                        <a href="https://mowmedia.com" target="_blank" rel="noreferrer">
                          <img src={mowmediaLogo} alt="MowMedia Logo" />
                        </a>
                      </div>
                      <p className="mt-4">
                        mowMedia brings the human element back to digital marketing. We cultivate one-to-one relationships
                        with every advertiser and publisher in our network to ensure only the best opportunities, driving
                        the most seamless user experience are presented to the most appropriate audiences.
                      </p>
                    </div>
                    <div className="col-lg-9">
                      <div className="row gx-5">
                        <div className="col-md-4">
                          <div className="footer-title">
                            Contacts
                          </div>
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <p>
                                OUR FAVORITE LOCAL BAR<br />
                                Cisco Brewers<br />
                                4 Star Point Suite 102,<br />
                                Stamford, CT 06902
                              </p>
                            </li>
                            <li className="nav-item">
                              <a href="https://www.ciscobrewers.com/" className="nav-link" target="_blank">
                                https://www.ciscobrewers.com/
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="footer-title">
                          </div>
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <p>
                                OUR OFFICE<br />
                                mowMedia, LLC<br />
                                542 Hopmeadow Street #163<br />
                                Simsbury, CT 06070
                              </p>
                            </li>
                            <li className="nav-item">
                              <a href="https://mowmedia.com" className="nav-link" target="_blank">
                                mowmedia.com
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="tel:203 240 6416" className="nav-link">
                                P: 203.240.6416
                              </a>
                            </li>
                            <li className="nav-item">
                              <a href="mailto:hello@mowmedia.com" className="nav-link">
                                E: hello@mowmedia.com
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-4">
                          <div className="footer-title">
                          </div>
                          <ul className="nav flex-column">
                            <li className="nav-item">
                              <p>
                                OUR FAVORITE COFFEE SHOP<br />
                                Espresso NEAT<br />
                                20 Grove St<br />
                                Darien, CT 06820
                              </p>
                            </li>
                            <li className="nav-item">
                              <a href="https://neatcoffee.com/" className="nav-link" target="_blank">
                                neatcoffee.com
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-11 copyright-row">
                  <div className="row justify-content-center">
                    <div className="col-auto text-center">
                      <div className="copyright-text">
                        © 2019-2022 mowMedia LLC, All Rights Reserved.
                        <br />
                        <Modal />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}