import React from 'react';
import { Hidden } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { SubMenu } from './SubMenu';

export const DesktopMenu = ({ menuItems, handleLogout }) => {
  const history = useHistory();
  const { user } = useCurrentUser();
  const userType = user?.admin ? 'admin' : 'user';
  return (
    <Hidden xsDown>
      {!!user ? (
        <div style={{ display: 'flex' }}>
          {menuItems[userType].map((item) => {
            return !!item.subMenu ? (
              <SubMenu key={item.label} menuItem={item} type='desktop'>
                <div key={item.label} className="header__logout">
                  <a onClick={() => history.push(item.path)}>{item.label}</a>
                </div>
              </SubMenu>
            ) : (
              <div key={item.label} className="header__logout">
                <a onClick={() => history.push(item.path)}>{item.label}</a>
              </div>
            );
          })}
          <div className="header__logout">
            <a onClick={handleLogout}>Logout</a>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex' }}>
          <div className="header__logout">
            <a onClick={() => history.push('/login')}>Login</a>
          </div>
        </div>
      )}
    </Hidden>
  );
};
