import React from 'react';
import { TableCell, TableHead, TableRow, Box } from '@material-ui/core';
import { useStyles } from './styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import cx from 'classnames';

const headCells = [
  {
    id: 'hasActiveCampaigns',
    numeric: false,
    disablePadding: true,
    label: 'Active Campaigns',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Spent $',
  },
  {
    id: 'referrer',
    numeric: true,
    disablePadding: false,
    label: 'Referrer',
  },
  {
    id: 'user-of',
    numeric: true,
    disablePadding: false,
    label: 'User of',
  },
  {
    id: 'login-link',
    numeric: true,
    disablePadding: false,
    label: 'Login Link',
  },
];

export const EnhancedTableHead = props => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tHead}>
      <TableRow style={{ border: 'none' }}>
        {headCells.map((headCell, colIndex) => {
          if (headCell.id === 'login-link' || headCell.id === 'user-of') {
            return (
              <TableCell
                key={headCell.id}
                className={cx(
                  classes.tHeader,
                  headCell.id === 'login-link' && classes.loginLink,
                  headCell.id === 'user-of' && classes.userOf
                )}
                align={colIndex === headCells.length - 1 ? 'right' : 'left'}
              >
                {headCell.label}
              </TableCell>
            );
          }

          return (
            <TableCell
              key={headCell.id}
              align={colIndex === headCells.length - 1 ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={cx(
                classes.tHeader,
                headCell.id === 'email' && classes.email,
                headCell.id === 'amount' && classes.spent
              )}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={classes.tHeadSortLabel}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
