import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Rellax from 'rellax';
import { useParams } from 'react-router-dom';
import logo from '../assets/images/mowpod-boost-logo.png';
import { unsubscribe } from '../api/users';

export default function ForgotPasswordForm() {
  const [unsubscribing, setUnsubscribing] = useState(false);
  const [email, setEmail] = useState('')
  const { email64 } = useParams();
  useEffect(() => {
    setUnsubscribing(true);
    window.scrollTo(0, 0);
    new Rellax(".rellax", {
      center: true,
    });
    unsubscribe(email64)
      .then((response) => {

        setUnsubscribing(false);
        setEmail(response.email);
      });
  }, []);

  return (
    <>
      <div className="backgrounds">
        <div className="background__rainbow">
          <div className={cx("background__rainbow--image rainbow--first login--rainbow rellax")} />
        </div>
        <div className="background-sun background-sun--login" />
      </div>
      <header>
        <div className="login-header">
          <img className="heder--logo" src={logo} alt="mowpod boost logotype" />
        </div>
      </header>
      <main className="main">
        <div className="main__login container" data-aos="fade-up" data-aos-delay="400" data-aos-duration="400">
          {!unsubscribing && <div>{email} is unsubscribed</div>}
        </div>
      </main>
    </>
  );
}
