import { makeStyles, withStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  link: {
    color: '#fff',
  },
  tHead: {
    fontWeight: 'bold',
    backgroundColor: theme,
  },
  tHeadSortLabel: {
    '&:hover': {
      color: 'grey !important',
    },
    '& svg': {
      fill: 'grey',
    },
    '&.Mui-active': {
      color: '#f56600 !important',
      '& svg': {
        fill: '#f56600',
      },
    },
  },
  tTableContainer: {
    background: 'transparent',
    boxShadow: 'none',
  },
  tHeader: {
    color: 'grey',
    border: 'none',
  },
  tTable: {
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 850,
    },
  },
  tTablePagination: {
    color: '#fff !important',
    border: 'none',
    '& svg': {
      fill: '#f56600',
    },
  },
  paginationBox: {
    '& button:disabled': {
      '& svg': {
        fill: 'grey',
      },
    },
  },
  tFirstItem: {
    border: 'none',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  tBodyItem: {
    border: 'none',
    position: 'relative',
  },
  wrapper: {
    paddingRight: 30,
  },
  title: {
    marginTop: 30,
  },
  iconButton: {
    color: '#fff',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  input: {
    border: '1px solid #fff',
    padding: '5px 15px',
    width: '100%',
  },
  marcking: {
    width: 10,
    height: 10,
    position: 'absolute',
  },
  redDot: {
    borderRadius: 50,
    backgroundColor: 'red',
    position: 'absolute',
  },
  searchInputWrapper: {
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  email: {
    width: 230,
  },
  loginLink: {
    width: 107,
  },
  spent: {
    width: 120,
  },
  userOf: {
    width: 84,
  },
}));

export const stylesForTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: '#fff',
  },
}));

export const stylesForTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#1C1B1B',
      color: '#fff',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#262424',
      color: '#fff',
    },
  },
}));
