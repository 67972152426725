import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const createStackAdaptCampaign = async (params) => {
  const headers = getHeaders();
  const res = await axios.post('/api/stackadapt/campaign', params, { headers });
  return res.data;
};

const cancelTokenSource = {};

export const getStats = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/stats', { headers, params });
  return res.data;
};

export const getLineItemById = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/line-item', {
    headers,
    params,
  });
  return res.data;
};

export const getLineItems = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/line-items', { headers, params });
  return res.data;
};

export const getAdvertiser = async (params) => {
  cancelTokenSource.getAdvertiser = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/advertiser', {
    headers,
    params,
  });
  return res.data;
};

export const getCampaignByIds = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/campaigns-by-ids', {
    headers,
    params,
  });
  return res.data;
};

export const getCampaigns = async () => {
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/campaigns', { headers });
  return res.data;
};

export const getGeos = async (params) => {
  if (cancelTokenSource.getGeos) {
    cancelTokenSource.getGeos.cancel('Operation canceled due to new request.');
  }
  cancelTokenSource.getGeos = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/top-cities', { headers, params, cancelToken: cancelTokenSource.getGeos.token });
  return res.data;
};

export const getDevices = async (params) => {
  if (typeof cancelTokenSource.getDevices !== typeof undefined) {
    cancelTokenSource.getDevices.cancel('Operation canceled due to new request.');
  }
  cancelTokenSource.getDevices = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/top-devices', { headers, params, cancelToken: cancelTokenSource.getDevices.token });
  return res.data;
};

export const getURLs = async (params) => {
  if (typeof cancelTokenSource.getURLs !== typeof undefined) {
    cancelTokenSource.getURLs.cancel('Operation canceled due to new request.');
  }
  cancelTokenSource.getURLs = axios.CancelToken.source();
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/top-urls', { headers, params, cancelToken: cancelTokenSource.getURLs.token });
  return res.data;
};

export const addCampaignGroup = async (data) => {
  const headers = getHeaders();
  const res = await axios.post('/api/stackadapt/add-campaign-group', data, { headers });
  return res.data;
};

export const addCampaigns = async (data) => {
  const headers = getHeaders();
  const res = await axios.post('/api/stackadapt/add-campaigns', data, { headers });
  return res.data;
};

export const getNativeAds = async () => {
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/native-ads', { headers });
  return res.data;
};

export const getReport = async (id) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stackadapt/report', { headers, params: { id }, responseType: 'blob' });
  return res.data;
};
