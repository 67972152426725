import { Button, Grid, Typography, InputBase } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { createReferrer, deleteReferrer, getReferrers, updateReferrer } from '../../api/referrers';
import Referrer from '../../components/Referrer';
import { useSearch } from '../../hooks/useSearch';
import { useStyles } from './styles';

export default function Referrers() {
  const classes = useStyles();
  const [referrers, setReferrers] = useState([]);
  const { data, searchValue, setSearchValue } = useSearch(referrers, 'code');

  const loadData = () => getReferrers()
    .then((response) => {
      setReferrers(response);
    });

  useEffect(() => {
    window.scrollTo(0, 0);
    loadData();
  }, []);

  const saveReferrer = async ({ code, price, active, id }) => {
    if (!code) {
      return toast.error('Code is required');
    }
    if (id) {
      await updateReferrer(id, { code, price, active });
      return loadData();
    }
    await createReferrer({ code, price });
    return loadData();
  };

  const removeReferrer = async (id) => {
    await deleteReferrer(id);
    loadData();
  };

  const addNew = () => {
    if (!referrers.find(item => item.isNew)) {
      setReferrers([{ isNew: true }, ...referrers]);
    }
  };

  const setEditeble = (i, value) => {
    const copyReferrers = [...referrers];
    copyReferrers[i].isEditable = value;
    if (copyReferrers[i].isNew && !value) {
      copyReferrers.splice(i, 1);
    }
    setReferrers(copyReferrers);
  };

  const handleEscKey = e => {
    if (e.keyCode === 27) {
      setSearchValue('');
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  };

  return (
    <>
      <Grid className={classes.wrapper} container direction="row" item md={12} justifyContent="center" alignItems="flex-start">
        <Grid container direction="row" item md={12} justifyContent="flex-start" alignItems="flex-start">
          <Grid className={classes.title} container direction="row" item md={7}>
            <Typography variant="h4">Referrers</Typography>
          </Grid>
          <Grid container direction="row" item md={3} justifyContent="flex-end">
            <InputBase
              className={classes.input}
              placeholder="Search"
              value={searchValue}
              onKeyDown={handleEscKey}
              onChange={handleSearch}
              inputProps={{ 'aria-label': 'search referrers' }}
            />
          </Grid>
          <Grid className={classes.btnWrapper} container direction="row" item md={2}>
            <Button variant="outlined" color="primary" className={classes.btn} onClick={addNew}>
              Add New
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.list} container direction="row" item md={12} justifyContent="flex-start" alignItems="center">
          {data.map((referrer, i) => (
            <Referrer
              key={i}
              referrer={referrer}
              index={i}
              saveReferrer={saveReferrer}
              deleteReferrer={removeReferrer}
              setEditeble={setEditeble}
            />
          ))}
        </Grid>
      </Grid>
    </>
  );
}