import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const createRssHost = async (params) => {
  const headers = getHeaders();
  const res = await axios.post('/api/rss-hosts', params, { headers });
  return res.data;
};

export const updateRssHost = async (id, params) => {
  const headers = getHeaders();
  const res = await axios.put(`/api/rss-hosts/${id}`, params, { headers });
  return res.data;
};

export const getRssHost = async (id) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/rss-hosts/${id}`, { headers });
  return res.data;
};

export const getRssHosts = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/rss-hosts`, { headers, params });
  return res.data;
}

export const deleteRssHost = async (id) => {
  const headers = getHeaders();
  const res = await axios.delete(`/api/rss-hosts/${id}`, { headers });
  return res.data;
};