import { numberWithCommas } from "../../../utils/utils";

export const initialState = {
  labels: [],
  datasets: [
    {
      label: '',
      backgroundColor: [],
      data: [],
      hoverOffset: 4,
    },
  ],
};

export const initialOptions = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: (context) => {
          return `${context.formattedValue}`;
        },
      },
    },
    datalabels: {
      color: '#fff',
      anchor: 'end',
      align: 'top',
      clamp: true,
      formatter: function (value) {
        return `${numberWithCommas(value)}`;
      },
    },
    legend: {
      display: true,
      labels: {
        color: 'white',
      },
    },
  },
  scales: {
    y: {
      grid: {
        borderColor: '#515151',
        tickColor: '#515151',
        color: '#515151',
      },
      grace: '5%',
      ticks: {
        color: '#fff',
        callback: function (value) {
          return `${numberWithCommas(value)}`;
        },
      },
      title: {
        align: 'end',
        display: true,
        text: 'Page Loads vs Campaigns',
        color: '#fff',
      },
    },
    x: {
      grid: {
        borderColor: '#515151',
        tickColor: '#515151',
        color: '#515151',
      },
      ticks: {
        color: '#fff',
        padding: 10,
      },
      title: {
        align: 'end',
        display: true,
        color: '#fff',
      },
    },
  },
};

