import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const getPaymentIntent = async (params) => {
  const res = await axios.post('/api/payment/create-payment-intent', {});
  return res.data;
};

export const getCheckoutSession = async (data) => {
  const headers = getHeaders();
  const res = await axios.post('/api/payment/checkout', data, { headers });
  return res.data;
};

export const getSource = async (data) => {
  const headers = getHeaders();
  const res = await axios.post('/api/payment/source', data, { headers });
  return res.data;
};

export const payment = async (data) => {
  const headers = getHeaders();
  const res = await axios.post('/api/payment', data, { headers });
  return res.data;
};