import { Menu, MenuItem, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  menuItem: {
    color: '#000',
  },
}));

const typeMenu = {
  desktop: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  mobile: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'rigth',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
};

export const SubMenu = ({ menuItem, type, children }) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (path) => {
    handleClose();
    history.push(path);
  };

  const newElement = React.cloneElement(children, {
    onClick: handleClick,
  });

  return (
    <>
      {newElement}
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={typeMenu[type].anchorOrigin}
        transformOrigin={typeMenu[type].transformOrigin}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItem.subMenu.map((item) => (
          <MenuItem key={item.label} className={classes.menuItem}>
            <a onClick={() => handleSelect(item.path)}>{item.label}</a>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
